import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';


interface CreateProductStep1Props {
    formik: any;
    artWorkdata:any
  
}



const CategoriesStep2: React.FC<CreateProductStep1Props > = ({ formik ,artWorkdata}) => {
  
  useEffect(() => {
    document.title = "Categories | Artflute + Admin React Template";
  }, []);
  const [medianslist, setmediansList] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [categorieslist, setcategoriesList] = useState<any[]>([]);
  useEffect(() => {
    console.log('artWorkdata in CategoriesStep2:', artWorkdata);
  }, [artWorkdata]);

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = formik;



  const fetchartmediansList = async () => {
    setLoader(true);
    try {
      

        const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_artmedians_dropdown`,
         
        );

        setmediansList(response.data);

    } catch (error) {
        console.error("Error fetching seller list:", error);
    } finally {
        setLoader(false);
    }
};



const fetchartcategoriesList = async () => {
    setLoader(true);
    try {
      

        const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_artcategories_dropdown`,
         
        );

        setcategoriesList(response.data);

    } catch (error) {
        console.error("Error fetching seller list:", error);
    } finally {
        setLoader(false);
    }
};


useEffect(() => {
   
    fetchartmediansList(); 
    fetchartcategoriesList();

}, []);

  const artSubjectOptions = [
    { value: 'Abstract', label: 'Abstract' },
    { value: 'Animals', label: 'Animals' },
    { value: 'Architecture', label: 'Architecture' },
    { value: 'Birds', label: 'Birds' },
    { value: 'Botanical', label: 'Botanical' },
    { value: 'Children', label: 'Children' },
    { value: 'City/Cityscape', label: 'City/Cityscape' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Dream', label: 'Dream' },
    { value: 'Ethnic', label: 'Ethnic' },
    { value: 'Family', label: 'Family' },
    { value: 'Fish', label: 'Fish' },
  ];

  const artStyleOptions = [
    { value: 'Abstract', label: 'Abstract' },
    { value: 'Figurative', label: 'Figurative' },
    { value: 'Folk', label: 'Folk' },
    { value: 'Decorative', label: 'Decorative' },
    { value: 'Cubism', label: 'Cubism' },
    { value: 'Impressionism', label: 'Impressionism' },
    { value: 'Expressionism', label: 'Expressionism' },
    { value: 'Minimalism', label: 'Minimalism' },
  ];

  const mediumsOptions = [
    { value: 'Pen and Ink', label: 'Pen and Ink' },
    { value: 'Acrylic', label: 'Acrylic' },
    { value: 'Encaustic', label: 'Encaustic' },
    { value: 'Gouache', label: 'Gouache' },
    { value: 'Egg Tempera', label: 'Egg Tempera' },
    { value: 'Mixed Media', label: 'Mixed Media' },
    { value: 'Oil', label: 'Oil' },
    { value: 'Watercolour and Gouache', label: 'Watercolour and Gouache' },
    { value: 'Tempera', label: 'Tempera' },
    { value: 'Watercolor', label: 'Watercolor' },
  ];

  const artTypeOptions = [
    { value: 'Roll', label: 'Roll' },
    { value: 'Stretched canvas', label: 'Stretched canvas' },
    { value: 'On hard board', label: 'On hard board' },
    { value: 'Pre framed', label: 'Pre framed' },
    { value: 'Test Art Types', label: 'Test Art Types' },
  ];

  const paletteOptions = [
    { value: 'Cement', label: 'Cement' },
    { value: 'Purple', label: 'Purple' },
    { value: 'Green', label: 'Green' },
    { value: 'Pink', label: 'Pink' },
    { value: 'Red', label: 'Red' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'Blue', label: 'Blue' },
  ];


    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Create Categories</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="surface">
                                                    <Form.Label>Surface </Form.Label>
                                                    <Form.Select
                                                        name="surface"
                                                        value={values.surface || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.surface && touched.surface}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="100_acid_free_reg_paper">100% Acid free Reg paper</option>
                                                        <option value="acrylic_sheet">Acrylic Sheet</option>
                                                        <option value="arches_paper">Arches paper</option>
                                                        <option value="board">Board</option>
                                                        <option value="canvas">Canvas</option>
                                                        <option value="handmade_paper">Handmade Paper </option>
                                                        <option value="wooden_canvas">Wooden Canvas</option>
                                                        <option value="Watercolor Paper">Watercolor Paper</option>
                                                        <option value="fabriano_paper">Fabriano Paper</option>
                                                        <option value="art_card_paper">Art Card Paper</option>
                                                        <option value="fibreglass">Fibreglass</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.surface}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
    <Form.Group className='mt-3' controlId="category_id">
        <Form.Label>Category <span className="text-danger">*</span></Form.Label>
        <Form.Select
            name="Category_id" 
            value={values.Category_id || ""} 
            onChange={handleChange} 
        >
            <option value="">Select Category</option>
            {categorieslist.map(item => (
                <option key={item.id} value={item.id}>{item.Category_name}</option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {errors.Category_id}
        </Form.Control.Feedback>
    </Form.Group>
</Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="art_subject">
                                                    <Form.Label>Art Subject </Form.Label>
                                                    <Select
                                                        isMulti
                                                        name="art_subject"
                                                        options={artSubjectOptions}
                                                        value={artSubjectOptions.filter(option =>
                                                            values.art_subject?.split(',').includes(option.value))
                                                        }
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map(option => option.value).join(',');
                                                            setFieldValue("art_subject", selectedValues);
                                                        }}
                                                        className={touched.art_subject && !!errors.art_subject ? 'is-invalid border border-danger ' : ''}
                                                    />
                                                    {touched.art_subject && errors.art_subject && (
                                                        <div className="text-danger">{errors.art_subject}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="art_style">
                                                    <Form.Label>Art Style </Form.Label>
                                                    <Select
                                                        isMulti
                                                        name="art_style"
                                                        options={artStyleOptions}
                                                        value={artStyleOptions.filter(option =>
                                                            values.art_style?.split(',').includes(option.value))
                                                        }
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map(option => option.value).join(',');
                                                            setFieldValue("art_style", selectedValues);
                                                        }}
                                                        className={touched.art_style && !!errors.art_style ? 'is-invalid border border-danger' : ''}
                                                    />
                                                    {touched.art_style && errors.art_style && (
                                                        <div className="text-danger">{errors.art_style}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                           
                                            <Col lg={6}>
    <Form.Group className='mt-3' controlId="Mediums">
        <Form.Label>Mediums</Form.Label>
        <Select
            isMulti
            name="Mediums"
            options={medianslist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={medianslist?.filter(option =>
                values.Mediums_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Mediums_id", selectedValues);
            }}
            className={touched.Mediums_id && !!errors.Mediums_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Mediums_id && errors.Mediums_id && (
            <div className="text-danger">{errors.Mediums_id}</div>
        )}
    </Form.Group>
</Col>

                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="art_type">
                                                    <Form.Label>Art Type <span className="text-danger">*</span></Form.Label>
                                                    <Select
                                                        isMulti
                                                        name="art_type"
                                                        options={artTypeOptions}
                                                        value={artTypeOptions.filter(option =>
                                                            values.art_type?.split(',').includes(option.value))
                                                        }
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map(option => option.value).join(',');
                                                            setFieldValue("art_type", selectedValues);
                                                        }}
                                                        className={touched.art_type && !!errors.art_type ? 'is-invalid border border-danger' : ''}
                                                    />
                                                    {touched.art_type && errors.art_type && (
                                                        <div className="text-danger">{errors.art_type}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="palette">
                                                    <Form.Label>Palette</Form.Label>
                                                    <Select

                                                        isMulti
                                                        name="palette"
                                                        options={paletteOptions}
                                                        value={paletteOptions.filter(option =>
                                                            values.palette?.split(',').includes(option.value))
                                                        }
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map(option => option.value).join(',');
                                                            setFieldValue("palette", selectedValues);
                                                        }}
                                                        className={touched.palette && !!errors.palette ? 'is-invalid border border-danger' : ''}
                                                    />
                                                    {touched.palette && errors.art_type && (
                                                        <div className="text-danger">{errors.palette}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="tags">
                                                    <Form.Label>tags <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="tags"
                                                        placeholder="Enter tags"
                                                        value={values.tags}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.tags && touched.tags}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.tags}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="signature">
                                                    <Form.Label>Signature </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="signature"
                                                        placeholder="Enter signature"
                                                        value={values.signature}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.signature && touched.signature}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.signature}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="edition_size">
                                                    <Form.Label>Edition Size </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="edition_size"
                                                        placeholder="Enter edition_size"
                                                        value={values.edition_size}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.edition_size && touched.edition_size}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.edition_size}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="timelines">
                                                    <Form.Label>Timelines </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="timelines"
                                                        placeholder="Enter timelines"
                                                        value={values.timelines}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.timelines && touched.timelines}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.timelines}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="year_released">
                                                    <Form.Label>Year Released (Only for serigraphs) </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="year_released"
                                                        placeholder="Enter year released"
                                                        value={values.year_released}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.year_released && touched.year_released}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.year_released}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="colors">
                                                    <Form.Label>Colors (Only for serigraphs) </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="colors"
                                                        placeholder="Enter colors"
                                                        value={values.colors}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.colors && touched.colors}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.colors}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="shipped_as">
                                                    <Form.Label>Shipped as <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="shipped_as"
                                                        value={values.shipped_as || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.shipped_as && touched.shipped_as}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Framed">Framed</option>
                                                        <option value="Rolled">Rolled</option>
                                                        <option value="Stretched">Stretched</option>
                                                        <option value="Crate">Crate</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.shipped_as}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default CategoriesStep2;
