import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import PersonalInformation from './PersonalInformation';
import SocialMedia from './SocialMedia';
import ArtistEssay from './ArtistEssay';
import ArtShows from './ArtShows';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import MainAddress from './MainAddress';
import Bankdetails from './Bankdetails';

const validationSchema = Yup.object().shape({
    // first_name: Yup.string().required('First name is required'),
    // last_name: Yup.string().required('Last name is required'),
    // email: Yup.string().email('Invalid email address').required('Email is required'),
    // phone: Yup.string().required('Phone number is required').matches(/^\d+$/, 'Phone number must be a valid number'),
    // password: Yup.string().required('Password is required'),
    // confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm password is required'),
    // gender: Yup.string().required('Gender is required'),
    // city: Yup.string().required('City is required'),
    // country: Yup.string().required('Country is required'),
    // zip_code: Yup.string().required('Zip code is required'),

    // profile: Yup.mixed().required('Profile Image is required'),
    // selection_of_enq_original: Yup.string().required('Selection of enquiry is required'),
    // comission_work_for_artist: Yup.string().required('Commission work for artist is required'),
    // artist_thumb_desktop: Yup.mixed().required('Artist thumb (Desktop) is required'),
    // artist_thumb_mobile: Yup.mixed().required('Artist thumb (Mobile) is required'),
    // photo_essay_title: Yup.string().required('Photo Essay Title is required'),
    // photo_essay_description: Yup.string().required('Photo Essay Description is required'),
    // essay_title1: Yup.string().required('Essay Title 1 is required'),
    // essay_image1: Yup.mixed().required('Essay Image One is required'),
    // essay_description1: Yup.string().required('Essay One Description is required'),
    // essay_title2: Yup.string().required('Essay Title 2 is required'),
    // essay_image2: Yup.mixed().required('Essay Image Two is required'),
    // essay_description2: Yup.string().required('Essay Two Description is required'),
    // essay_title3: Yup.string().required('Essay Title 3 is required'),
    // essay_image3: Yup.mixed().required('Essay Image Three is required'),
    // essay_description3: Yup.string().required('Essay Three Description is required'),
    // essay_title4: Yup.string().required('Essay Title 4 is required'),
    // essay_image4: Yup.mixed().required('Essay Image Four is required'),
    // essay_description4: Yup.string().required('Essay Four Description is required'),
    // essay_title5: Yup.string().required('Essay Title 5 is required'),
    // essay_image5: Yup.mixed().required('Essay Image Five is required'),
    // essay_description5: Yup.string().required('Essay Five Description is required'),
    // essay_title6: Yup.string().required('Essay Title 6 is required'),
    // essay_image6: Yup.mixed().required('Essay Image Six is required'),
    // essay_description6: Yup.string().required('Essay Six Description is required'),
    // facebook_profile_url: Yup.string().url('Invalid URL').required('Facebook username is required'),
    // twitter: Yup.string().url('Invalid URL').required('Twitter username is required'),
    // linkedIn_profile_url: Yup.string().url('Invalid URL').required('LinkedIn username is required'),
    // website_url: Yup.string().url('Invalid URL').required('Website URL is required'),
    // blog_url: Yup.string().url('Invalid URL').required('Blog URL is required'),
    // seo_url: Yup.string().url('Invalid URL').required('SEO URL is required'),
    // artist_education: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Education name is required'),
    //     })
    // ),
    // artist_awards_recog: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Award name is required'),
    //     })
    // ),
    // artist_desciplines: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Discipline name is required'),
    //     })
    // ),
    // art_gallary_assoc: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Gallery association name is required'),
    //     })
    // ),
    // other_assoc: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Other association name is required'),
    //     })
    // ),
    // ongoing_events: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Ongoing event name is required'),
    //     })
    // ),
    // upcoming_events: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Upcoming event name is required'),
    //     })
    // ),
    // exhibitions_events: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Exhibition event name is required'),
    //     })
    // ),
    // collection: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Collection name is required'),
    //     })
    // ),
});

const AddArtist: React.FC = () => {
    const [educations, setEducations] = useState([{ name: '' }]);
    const [artGallaryAssoc, setArtGallaryAssoc] = useState([{ name: '' }]);
    const [otherAssoc, setOtherAssoc] = useState([{ name: '' }]);
    const [awards, setAwards] = useState([{ name: '' }]);
    const [disciplines, setDisciplines] = useState([{ name: '' }]);
    const [onGoingEvents, setOnGoingEvents] = useState([{ name: '' }]);
    const [upcomingEvents, setUpcomingEvents] = useState([{ name: '' }]);
    const [exhibitionsEvents, setExhibitionsEvents] = useState([{ name: '' }]);
    const [collectioned, setCollectioned] = useState([{ name: '' }]);

    document.title = "Settings | Artflute + Admin React Template";

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            gender: '',
            profile: null,
            city: '',
            country: '',
            zip_code: '',
            confirm_password: '',
            selection_of_enq_original: '',
            comission_work_for_artist: '',
            artist_thumb_desktop: null,
            artist_thumb_mobile: null,
            artist_education: educations,
            art_gallary_assoc: artGallaryAssoc,
            other_assoc: otherAssoc,
            ongoing_events: onGoingEvents,
            upcoming_events: upcomingEvents,
            exhibitions_events: exhibitionsEvents,
            collection: collectioned,
            artist_awards_recog: awards,
            artist_desciplines: disciplines,
            facebook_profile_url: '',
            twitter: '',
            linkedIn_profile_url: '',
            website_url: '',
            blog_url: '',
            seo_url: '',
            photo_essay_title: '',
            photo_essay_description: '',
            essay_title1: '',
            essay_image1: null,
            essay_description1: '',
            essay_title2: '',
            essay_image2: null,
            essay_description2: '',
            essay_title3: '',
            essay_image3: null,
            essay_description3: '',
            essay_title4: '',
            essay_image4: null,
            essay_description4: '',
            essay_title5: '',
            essay_image5: null,
            essay_description5: '',
            essay_title6: '',
            essay_image6: null,
            essay_description6: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values, "Form values");
            try {
                const tokenString = localStorage.getItem('authUser');
                const token = tokenString ? JSON.parse(tokenString)?.token : '';

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/add_artist`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                resetForm();
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        },
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Settings" pageTitle="Accounts" />

                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <PersonalInformation
                                formik={formik}
                                setEducations={setEducations}
                                setAwards={setAwards}
                                setDisciplines={setDisciplines}
                            />
                            <ArtShows
                                formik={formik}
                                setArtGallaryAssoc={setArtGallaryAssoc}
                                setOtherAssoc={setOtherAssoc}
                                setOnGoingEvents={setOnGoingEvents}
                                setUpcomingEvents={setUpcomingEvents}
                                setExhibitionsEvents={setExhibitionsEvents}
                                setCollectioned={setCollectioned}
                            />
                              <MainAddress formik={formik}/>

                            <SocialMedia formik={formik} />

                            <ArtistEssay formik={formik} />
<Bankdetails formik={formik} />
                            {/* <MainAddress formik={formik}/> */}

                            <Row>
                                <Col lg={2} className='ms-auto'>
                                    <Button type="submit" className="btn btn-primary my-3 w-100">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </FormikProvider>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AddArtist;