import React, { useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Nav, Row } from 'react-bootstrap';

// import CreateProductStep1 from 'pages/Artwoks/CreateProduct/CreateProductStep1';
import EditProductStep1 from 'pages/Editartwork/Editproduct/Editproduct';
import axios from 'axios';
import EditcategoriesStep2 from 'pages/Editartwork/Editcategory/Editcategory';
import EditseoStep3 from 'pages/Editartwork/Editseo';
import EditsupportingImageStep4 from 'pages/Editartwork/Editsupporting';
import EditSuccessModal from './EditSuccessModal';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const getValidationSchema = (step: number) => {
  switch (step) {
    case 0:
      return Yup.object().shape({
        // Title: Yup.string().required('Title is required'),
        // Dimension_width: Yup.string().required('Dimension width is required'),
        // Dimension_height: Yup.string().required('Dimension height is required'),
        // Orientation: Yup.string().required('Orientation is required'),
        // Artwork_image: Yup.mixed().required('Artwork image is required'),
        // Artwork_full_image: Yup.mixed().required('Artwork full image is required'),
      });
    case 1:
      return Yup.object().shape({
        // shipped_as: Yup.string().required('Shipped as is required'),
        // category_id: Yup.string().required('Category is required'),
      });
    case 2:
      return Yup.object().shape({
        // seo_title: Yup.string().required('Seo title is required'),
      });
    case 3:
      return Yup.object().shape({
        // supporting_image_1: Yup.mixed().required('Supporting image 1 is required'),
      });
    default:
      return Yup.object().shape({});
  }
};

const steps = ['Artwork', 'Categories/Tags', 'Seo', 'Supporting Images'];

interface StepContentProps {
  step: number;
  formik: FormikProps<any>;
}

const StepContent: React.FC<StepContentProps> = ({ step, formik }) => {
  switch (step) {
    case 0:
      return (
        <EditProductStep1 formik={formik} />
      );
    case 1:
      return (
        <EditcategoriesStep2 formik={formik} />
      );
    case 2:
      return (
        <EditseoStep3 formik={formik} />
      );
    case 3:
      return (
        <EditsupportingImageStep4 formik={formik} />
      );
    default:
      return null;
  }
};

const StepperWizard: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [artWorkId, setArtWorkId] = useState<any>()
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const navigate = useNavigate();

  
const location = useLocation();
const { data: id } = location.state || {};

console.log('EditReceived ID', id);


  const getInitialValues = () => {
    switch (step) {
      case 0:
        return {
            id:'',
            Artist_id: '',
          Title: '',
          Slug: '',
          Label: '',
          Description: '',
          Status: '',
          Sold_out: '',
          Weight: '',
          Dimension_depth: '',
          Originals_price: '',
          Originals_price_dollar: '',
          Year: '',
          Dimension_width: '',
          Is_available_for_print_sale: '',
          Month: '',
          Dimension_height: '',
          Limited_period_discount: '',
          Is_vailable_for_desktop_art: '',
          Creation_place: '',
          Orientation_id: '',
          Price_on_request: '',
          Is_available_for_original_sale:'',
          Label_for_date: '',
          Artwork_image: null,
          Artwork_webp_image: null,
          Artwork_full_image: null,
          Artwork_full_webp_image: null,
          artwork_id: '',
        };
      case 1:
        return {
      
          Category_id: '',
          Mediums_id: '',
          Art_style_id: '',
          Art_subject_id: '',
          Surface_id: '',
          Art_type_id: '',
          Palette_id: '',
          Tags:'',
          Signature:'',
          Edition_size:'',
          Timelines:'',
          Year_released:'',
          Colors:'',
          Shipped_as:'',
        };
      case 2:
        return {
          seo_title: '',
          seo_keyword: '',
          seo_description: '',
          seo_image: null,
          artwork_id: '',
        };
      case 3:
        return {
          supporting_image_1: null,
          supporting_image_2: null,
          supporting_image_3: null,
          supporting_image_4: null,
          supporting_image_5: null,
          supporting_image_6: null,
          supporting_image_7: null,
          supporting_image_8: null,
          supporting_image_9: null,
          supporting_image_10: null,
          supporting_image_11: null,
          artwork_id: '',
        };
      default:
        return {};
    }
  };
  const getFilteredValues = (values: any) => {
    switch (step) {
      case 0:
        return {
            Artist_id: values.Artist_id,
          Title: values.Title,
          Slug: values.Slug,
          Label: values.Label,
          Description: values.Description,
          Status: values.Status,
          Sold_out: values.Sold_out,
          Weight: values.Weight,
          Dimension_depth: values.Dimension_depth,
          Originals_price: values.Originals_price,
          Originals_price_dollar: values.Originals_price_dollar,
          Year: values.Year,
          Dimension_width: values.Dimension_width,
          Is_available_for_print_sale: values.Is_available_for_print_sale,
          Month: values.Month,
          Dimension_height: values.Dimension_height,
          Limited_period_discount: values.Limited_period_discount,
          Is_vailable_for_desktop_art: values.Is_vailable_for_desktop_art,
          Creation_place: values.Creation_place,
          Orientation: values.Orientation,
          Price_on_request: values.Price_on_request,
          Label_for_date: values.Label_for_date,
          Artwork_image: values.Artwork_image,
          Artwork_webp_image: values.Artwork_webp_image,
          Artwork_full_image: values.Artwork_full_image,
          Artwork_full_webp_image: values.Artwork_full_webp_image,
          
          Is_available_for_original_sale: values.Is_available_for_original_sale,
        };
      case 1:
        return {
            Surface_id: values.Surface_id,
            Category_id: values.Category_id,
            Art_subject_id: values.Art_subject_id,
            Art_style_id: values.Art_style_id,
            Mediums_id: values.Mediums_id,
            Art_type_id: values.Art_type_id,
            Palette_id: values.Palette_id,
            Tags: values.Tags,
            Signature: values.Signature,
            Edition_size: values.Edition_size,
            Timelines: values.Timelines,
            Year_released: values.Year_released,
          Colors: values.Colors,
          Shipped_as: values.Shipped_as,
          artwork_id: artWorkId,
        };
      case 2:
        return {
          seo_title: values.seo_title,
          seo_keyword: values.seo_keyword,
          seo_description: values.seo_description,
          seo_image: values.seo_image,
          artwork_id: artWorkId,
        };
      case 3:
        return {
          supporting_image_1: values.supporting_image_1,
          supporting_image_2: values.supporting_image_2,
          supporting_image_3: values.supporting_image_3,
          supporting_image_4: values.supporting_image_4,
          supporting_image_5: values.supporting_image_5,
          supporting_image_6: values.supporting_image_6,
          supporting_image_7: values.supporting_image_7,
          supporting_image_8: values.supporting_image_8,
          supporting_image_9: values.supporting_image_9,
          supporting_image_10: values.supporting_image_10,
          supporting_image_11: values.supporting_image_11,
          artwork_id: artWorkId,
        };
      default:
        return {};
    }
  };

  const handleNext = async (formikProps: FormikProps<any>) => {
    try {
      const errors = await formikProps.validateForm();
  
      if (Object.keys(errors).length === 0) {
        const endpoint =
          step === 0
            ? '/auth/edit_art_worksartist'
            : step === 1
            ? '/auth/Edit_categories'
            : step === 2
            ? '/auth/Edit_seo'
            : '/auth/Edit_supporting';
  
        const filteredValues = {
          ...getFilteredValues(formikProps.values),
          artwork_id: id || artWorkId,
        };
  
        const tokenString = localStorage.getItem('authUser');
        const token = tokenString ? JSON.parse(tokenString)?.token : '';
  
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
          filteredValues,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response && response.data && response.data.user) {
          setArtWorkId(response.data.user.id);
        } else {
          throw new Error('Something went wrong');
        }
  
        if (step < steps.length - 1) {
          setStep(step + 1);
          setCompletedSteps([...completedSteps, step]);
        } else {
          setCompletedSteps([...completedSteps, step]);
          formikProps.resetForm();
          setShowSuccessModal(true);
        }
      } else {
        formikProps.setTouched(
          Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        );
      }
    } catch (error) {
      console.error('Error during validation or API call:', error);
    }
  };
  
  const handleStepClick = async (index: number) => {
    console.log(`Attempting to navigate to step: ${index}`);
    console.log(`Current step: ${step}`);
    console.log(`Completed steps: ${completedSteps}`);
  
    if (index === step + 1 || completedSteps.includes(index)) {
      console.log(`Navigating to step: ${index}`);
      
      if (index !== step) {
        console.log('Triggering API call for step:', index);
        await handleApiCall(index);
      }
  
      setStep(index);
  
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]); 
      }
    } else {
      console.log(`Step navigation blocked for step: ${index}`);
    }
  };
  
  
  
  const handleApiCall = async (currentStep: number) => {
    try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString)?.token : '';
      console.log(currentStep, "bbbbbbbbbbbbbbbbbbbbbbbbbb" )
      const endpoint =
        currentStep === 0
          ? '/auth/edit_art_worksartist'
          : currentStep === 1
          ? '/auth/Edit_categories'
          : currentStep === 2
          ? '/auth/Edit_seo'
          : '/auth/Edit_supporting';
      
      // Fetch filtered values for the current step
      const filteredValues = {
        ...getFilteredValues(currentStep), 
        artwork_id: id || artWorkId,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
        filteredValues,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response && response.data && response.data.user) {
        setArtWorkId(response.data.user.id);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };
  

  const handleBack = () => setStep(step - 1);

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };


  return (
    <Row>
      <Col md={3}>
        <Nav className="flex-column">
          {steps.map((label, index) => (
            <Nav.Item key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <Nav.Link
                active={index === step}
                onClick={() => handleStepClick(index)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span style={{ marginRight: '8px' }}>
                  {completedSteps.includes(index) ? '✔' : '✖'}
                </span>
                Step {index + 1}: {label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col md={9}>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={getValidationSchema(step)}
          onSubmit={async (values, { resetForm }: any) => {
            try {
              const tokenString = localStorage.getItem('authUser');
              const token = tokenString ? JSON.parse(tokenString)?.token : '';

              const endpoint =
                step === 0
                  ? '/auth/edit_art_worksartist'
                  : step === 1
                  ? '/auth/Edit_categories'
                  : step === 2
                  ? '/auth/Edit_seo'
                  : '/auth/Edit_supporting';

              const filteredValues = {
                ...getFilteredValues(values),
                artwork_id: id || artWorkId,
              };

              const response = await axios.post(
                `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
                filteredValues,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response && response.data && response.data.user) {
                setArtWorkId(response.data.user.id);
                resetForm();
              } else {
                throw new Error('Something went wrong');
              }

              resetForm();
            } catch (error) {
              console.error('Error submitting form:', error);
            }
          }}
        >
          {(formik) => (
            <Form>
              <StepContent step={step} formik={formik} />
              <div className="d-flex justify-content-between mt-4">
                <Button variant="secondary" onClick={handleBack} disabled={step === 0}>
                  Back
                </Button>
                <Button variant="primary" onClick={() => handleNext(formik)}>
                  {step === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <EditSuccessModal show={showSuccessModal} onClose={handleModalClose} />
    </Row>
  );
};

export default StepperWizard;