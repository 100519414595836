

import BreadCrumb from "Common/BreadCrumb";
import StepperWizard from "Common/EditcollectionsStepper";
import React from "react";
import {  Container } from "react-bootstrap";

function Editcolection() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Collection" pageTitle="Collection" />
          <StepperWizard />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Editcolection;
