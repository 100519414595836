import BreadCrumb from "Common/BreadCrumb";
import StepperWizard from "Common/Collectionstepper";
import React from "react";
import {  Container } from "react-bootstrap";

function Addcolection() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Collection" pageTitle="Collection" />
          <StepperWizard />
        </Container>
      </div>
    </React.Fragment>
  );
}




export default Addcolection;
