
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

interface CreateProductStep1Props {
    formik: any;
}

const EditcategoriesStep2: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template"
    const [palletslist, setpalletsList] = useState<any[]>([]);

    const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = formik;

    const artSubjectOptions = [
        { value: 'Abstract', label: 'Abstract' },
        { value: 'Animals', label: 'Animals' },
        { value: 'Architecture', label: 'Architecture' },
        { value: 'Birds', label: 'Birds' },
        { value: 'Botanical', label: 'Botanical' },
        { value: 'Children', label: 'Children' },
        { value: 'City/Cityscape', label: 'City/Cityscape' },
        { value: 'Dance', label: 'Dance' },
        { value: 'Dream', label: 'Dream' },
        { value: 'Ethnic', label: 'Ethnic' },
        { value: 'Family', label: 'Family' },
        { value: 'Fish', label: 'Fish' },
    ];

    const artStyleOptions = [
        { value: 'Abstract', label: 'Abstract' },
        { value: 'Figurative', label: 'Figurative' },
        { value: 'Folk', label: 'Folk' },
        { value: 'Decorative', label: 'Decorative' },
        { value: 'Cubism', label: 'Cubism' },
        { value: 'Impressionism', label: 'Impressionism' },
        { value: 'Expressionism', label: 'Expressionism' },
        { value: 'Minimalism', label: 'Minimalism' },
    ];

    const mediumsOptions = [
        { value: 'Pen and Ink', label: 'Pen and Ink' },
        { value: 'Acrylic', label: 'Acrylic' },
        { value: 'Encaustic', label: 'Encaustic' },
        { value: 'Gouache', label: 'Gouache' },
        { value: 'Egg Tempera', label: 'Egg Tempera' },
        { value: 'Mixed Media', label: 'Mixed Media' },
        { value: 'Oil', label: 'Oil' },
        { value: 'Watercolour and Gouache', label: 'Watercolour and Gouache' },
        { value: 'Acrylic', label: 'Acrylic' },
        { value: 'Watercolour and Acrylic', label: 'Watercolour and Acrylic' },
        { value: 'Tempera', label: 'Tempera' },
        { value: 'Watercolor', label: 'Watercolor' },
    ]
    const artTypeOptions = [
        { value: 'Roll', label: 'Roll' },
        { value: 'Stretched canvas', label: 'Stretched canvas' },
        { value: 'On hard board', label: 'On hard board' },
        { value: 'Pre framed', label: 'Pre framed' },
        { value: 'Test Art Types', label: 'Test Art Types' },
    ]

    const paletteOptions = [
        { value: 'Cement', label: 'Cement' },
        { value: 'Purple', label: 'Purple' },
        { value: 'Green', label: 'Green' },
        { value: 'Pink', label: 'Pink' },
        { value: 'Red', label: 'Red' },
        { value: 'Orange', label: 'Orange' },
        { value: 'Yellow', label: 'Yellow' },
        { value: 'Blue', label: 'Blue' },
    ]


    const [categorylist, setcategoryList] = useState<any[]>([]);

    const [typelist, settypeList] = useState<any[]>([]);

    const [stylelist, setstyleList] = useState<any[]>([]);

    const [subjectslist, setsubjectsList] = useState<any[]>([]);
    

    const [surfacelist, setsurfaceList] = useState<any[]>([]);
    const [medianslist, setmediansList] = useState<any[]>([]);

    const [categorieslist, setcategoriesList] = useState<any[]>([]);

    const [loader, setLoader] = useState<boolean>(false);
    const [selectedcategory, setSelectedcategory] = useState<any>(null);
    const [deleteSellerId, setDeleteSellerId] = useState<any>(null);
    const [statusdate, setstatusdate] = useState<any>([]);;

    


    const fetchartstyleList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artstyle_dropdown`,
             
            );

            setstyleList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetcharttypeList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_arttype_dropdown`,
             
            );

            settypeList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetchartsubjectList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artsubject_dropdown`,
             
            );

            setsubjectsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetchartmediansList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artmedians_dropdown`,
             
            );

            setmediansList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetchartpalletsList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artpallets_dropdown`,
             
            );

            setpalletsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartcategoriesList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artcategories_dropdown`,
             
            );

            setcategoriesList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartsurfaceList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artsurface_dropdown`,
             
            );

            setsurfaceList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };



    
    useEffect(() => {
        fetchartcategoriesList();
        fetchartstyleList();  
        fetchartsurfaceList();
        fetcharttypeList();     
        fetchartsubjectList(); 
        fetchartmediansList(); 
        fetchartpalletsList(); 
    }, []);


    console.log(JSON.stringify(surfacelist, null, 2), "surfacelist");

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Edit Categories</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                        <Col lg={6}>
    <Form.Group className='mt-3' controlId="surface">
        <Form.Label>Surface</Form.Label>
        <Form.Select
            name="Surface_id" 
            value={values.Surface_id || ""} 
            onChange={handleChange}
            isInvalid={!!errors.Surface_id && touched.Surface_id}
        >
            <option value="">Select</option>
            {surfacelist.map(item => (
                <option key={item.id} value={item.id}>{item.Label}</option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {errors.Surface_id}
        </Form.Control.Feedback>
    </Form.Group>
</Col>

                                            <Col lg={6}>
    <Form.Group className='mt-3' controlId="category_id">
        <Form.Label>Category <span className="text-danger">*</span></Form.Label>
        <Form.Select
            name="Category_id" 
            value={values.Category_id || ""} 
            onChange={handleChange} 
        >
            <option value="">Select Category</option>
            {categorieslist.map(item => (
                <option key={item.id} value={item.id}>{item.Category_name}</option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {errors.Category_id}
        </Form.Control.Feedback>
    </Form.Group>
</Col>

<Col lg={6}>
    <Form.Group className='mt-3' controlId="art_subject">
        <Form.Label>Art Subject</Form.Label>
        <Select
            isMulti
            name="art_subject"
            options={subjectslist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={subjectslist?.filter(option =>
                values.Art_subject_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Art_subject_id", selectedValues);
            }}
            className={touched.Art_subject_id && !!errors.Art_subject_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Art_subject_id && errors.Art_subject_id && (
            <div className="text-danger">{errors.Art_subject_id}</div>
        )}
    </Form.Group>
</Col>

<Col lg={6}>
    <Form.Group className='mt-3' controlId="art_subject">
        <Form.Label>Art Style</Form.Label>
        <Select
            isMulti
            name="art_subject"
            options={stylelist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={stylelist?.filter(option =>
                values.Art_style_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Art_style_id", selectedValues);
            }}
            className={touched.Art_style_id && !!errors.Art_style_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Art_style_id && errors.Art_style_id && (
            <div className="text-danger">{errors.Art_style_id}</div>
        )}
    </Form.Group>
</Col>



                                          

<Col lg={6}>
    <Form.Group className='mt-3' controlId="Mediums">
        <Form.Label>Mediums</Form.Label>
        <Select
            isMulti
            name="Mediums"
            options={medianslist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={medianslist?.filter(option =>
                values.Mediums_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Mediums_id", selectedValues);
            }}
            className={touched.Mediums_id && !!errors.Mediums_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Mediums_id && errors.Mediums_id && (
            <div className="text-danger">{errors.Mediums_id}</div>
        )}
    </Form.Group>
</Col>


                                           

<Col lg={6}>
    <Form.Group className='mt-3' controlId="Art_Type">
        <Form.Label>Art Type </Form.Label>
        <Select
            isMulti
            name="Art_Type "
            options={typelist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={typelist?.filter(option =>
                values.Art_type_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Art_type_id", selectedValues);
            }}
            className={touched.Art_type_id && !!errors.Art_type_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Art_type_id && errors.Art_type_id && (
            <div className="text-danger">{errors.Art_type_id}</div>
        )}
    </Form.Group>
</Col>


                                          

<Col lg={6}>
    <Form.Group className='mt-3' controlId="Palette_id">
        <Form.Label>Palette</Form.Label>
        <Select
            isMulti
            name="Palette_id "
            options={palletslist?.map(item => ({
                value: item.id,
                label: item.Label
            })) || []} 
            value={palletslist?.filter(option =>
                values.Palette_id?.includes(option.id.toString())
            ).map(option => ({
                value: option.id,
                label: option.Label
            })) || []}
            onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value.toString()) 
                    : []; 
                setFieldValue("Palette_id", selectedValues);
            }}
            className={touched.Palette_id && !!errors.Palette_id ? 'is-invalid border border-danger' : ''}
        />
        {touched.Palette_id && errors.Palette_id && (
            <div className="text-danger">{errors.Palette_id}</div>
        )}
    </Form.Group>
</Col>


                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="tags">
                                                    <Form.Label>tags <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="tags"
                                                        placeholder="Enter tags"
                                                        value={values.tags}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.tags && touched.tags}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.tags}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="signature">
                                                    <Form.Label>Signature </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="signature"
                                                        placeholder="Enter signature"
                                                        value={values.signature}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.signature && touched.signature}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.signature}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="edition_size">
                                                    <Form.Label>Edition Size </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="edition_size"
                                                        placeholder="Enter edition_size"
                                                        value={values.edition_size}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.edition_size && touched.edition_size}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.edition_size}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="timelines">
                                                    <Form.Label>Timelines </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="timelines"
                                                        placeholder="Enter timelines"
                                                        value={values.timelines}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.timelines && touched.timelines}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.timelines}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="year_released">
                                                    <Form.Label>Year Released (Only for serigraphs) </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="year_released"
                                                        placeholder="Enter year released"
                                                        value={values.year_released}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.year_released && touched.year_released}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.year_released}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="colors">
                                                    <Form.Label>Colors (Only for serigraphs) </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="colors"
                                                        placeholder="Enter colors"
                                                        value={values.colors}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.colors && touched.colors}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.colors}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className='mt-3' controlId="shipped_as">
                                                    <Form.Label>Shipped as <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="shipped_as"
                                                        value={values.shipped_as || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.shipped_as && touched.shipped_as}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Framed">Framed</option>
                                                        <option value="Rolled">Rolled</option>
                                                        <option value="Stretched">Stretched</option>
                                                        <option value="Crate">Crate</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.shipped_as}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default EditcategoriesStep2;

