import React, { useState, useMemo, useEffect} from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import TableContainer from "Common/TableContainer";
import { ListView } from "Common/data";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { useDebounce } from 'use-debounce';

const ArtMediumsListTable = () => {
   
    

        const [modal_AddUserModals, setmodal_AddUserModals] = useState<boolean>(false);
        const [Mediumlist, setMediumsList] = useState<any>([]);
        const [modal_DeleteConfirm, setModalDeleteConfirm] = useState(false);
        const [selectedItem, setSelectedItem] = useState<any>(null);
        const [showAddModal, setShowAddModal] = useState(false);
        const [showEditModal, setShowEditModal] = useState(false);
        const [isMultiDeleteButton, setIsMultiDeleteButton] = useState<boolean>(false);
        const [loader, setLoader] = useState<boolean>(false);
    
        const [searchTerm, setSearchTerm] = useState('');
        const [debouncedSearchTerm] = useDebounce(searchTerm, 500); 
        const [noData, setNoData] = useState<boolean>(false); 
    
        const [page, setPage] = useState(0); 
    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [limit, setLimit] = useState<number>(1);

    const perPageData = 10;
    
        const fetchMediumList = async (page: number, limit: number) => {
            setLoader(true);
            try {
    
                const tokenString = localStorage.getItem('authUser');
                    let token = '';
        
                    if (tokenString) {
                        const tokenObject = JSON.parse(tokenString);
                        token = tokenObject.token;
                    }
                const queryParams = new URLSearchParams({
                    search: debouncedSearchTerm, 
                    page: page.toString(),
                limit: limit.toString(),
                }).toString();
    
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASEURL}/auth/get_art_medians?${queryParams}`, 
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );
       
        console.log(response.data, "mmmmmmmmmmmmmmmmmmmmmmm" )
        if (response?.data?.length === 0) {
            setMediumsList([]); // Clear data
            setNoData(true);
        } else {
            setMediumsList(response.data);
            setNoData(false);
        }
    } catch (error) {
        console.error('API Error:', error);
        setMediumsList([]); // Clear data on error
        setNoData(true);
    } finally {
        setLoader(false);
    }
};

        useEffect(() => {
            fetchMediumList(currentPage, limit);
        }, [debouncedSearchTerm, currentPage, limit]); 
        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
        };
    
    console.log(Mediumlist, "cccccccccccccccc")
    console.log(JSON.stringify(Mediumlist, null, 2), "Response data");
    
    
        const handleDeleteClick = (item: any) => {
            setSelectedItem(item);
            setModalDeleteConfirm(true);
        };
    
        const handleDeleteConfirm = async () => {
            if (selectedItem) {
                console.log('Deleting item:', selectedItem);
                
                setModalDeleteConfirm(false);
                setLoader(true);
        
                try {
                    const tokenString = localStorage.getItem('authUser');
                    let token = '';
        
                    if (tokenString) {
                        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                        token = tokenObject.token;
                    }
        
                    await axios.get(
                        `${process.env.REACT_APP_API_BASEURL}/auth/delete_art_medians`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            params: {
                                id: selectedItem.id,
                            },
                        }
                    );
        
                    
                 
                    fetchMediumList(page, limit);
        
                } catch (error) {
                    console.error('Error deleting item:', error);
                } finally {
                    setLoader(false);
                    setModalDeleteConfirm(false);
                }
            }
        };
        
        
    
    
        const handleEditClick = (item: any) => {
            setSelectedItem(item);
            formikEdit.setValues({
                id: item.id || '',
                name: item.name || '',
                category: item.category || '',
            });
            setShowEditModal(true);
        };
    
    
        const handleAddClick = () => {
            formikAdd.resetForm();
            setShowAddModal(true);
        };
    
        const handleClose = () => {
            setShowAddModal(false);
            setShowEditModal(false);
            setModalDeleteConfirm(false);
        };
    
        const formikAdd = useFormik({
            initialValues: {
                name: '',
                category: '',
            },
            validationSchema: Yup.object({
                // name: Yup.string().required('name is required'),
                // category: Yup.string().required('category is required'),
            }),
            onSubmit: async (values, { resetForm }) => {
                console.log('Adding new item:', values);
                setLoader(true);
                try {
                    const tokenString = localStorage.getItem('authUser');
                    let token = '';
        
                    if (tokenString) {
                        const tokenObject = JSON.parse(tokenString);
                        token = tokenObject.token;
                    }
        
                    await axios.post(
                        `${process.env.REACT_APP_API_BASEURL}/auth/add_art_medians`,
                        values,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    );
        
                    resetForm();
                    handleClose();
                    fetchMediumList(page, limit);
                } catch (error) {
                    console.error('Error adding item:', error);
                } finally {
                    setLoader(false);
                }
            },
        });
        
    
        
    
        const formikEdit = useFormik({
            enableReinitialize: true,
            initialValues: {
                id:'',
                name: '',
                category: '',
            },
            validationSchema: Yup.object({
                // name: Yup.string().required('name is required'),
                // category: Yup.string().required('category is required'),
            }),
            onSubmit: async (values, { resetForm }) => {
                console.log('Updating item:', values);
                setLoader(true);
                try {
                    const tokenString = localStorage.getItem('authUser');
                    let token = '';
        
                    if (tokenString) {
                        const tokenObject = JSON.parse(tokenString);
                        token = tokenObject.token;
                    }
        
                    await axios.post(
                        `${process.env.REACT_APP_API_BASEURL}/auth/edit_art_medians`,
                        values,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    );
        
                    resetForm();
                    handleClose();
                    fetchMediumList(page, limit);
                } catch (error) {
                    console.error('Error adding item:', error);
                } finally {
                    setLoader(false);
                }
            },
        });
         
    
    
       
    
    const ListView = Mediumlist.map((item: { id: any; name: any; category: any; }) => ({
        id: item.id,
        name: item.name,
        category: item.category,
    }));
        const columns = useMemo(
            () => [
                {
                    Header: "Name",
                    accessor: (cellProps: any) => (
                        <span className="fw-medium">{cellProps.name}</span>
                    ),
                    disableFilters: true,
                    filterable: true,
                },
                {
                    Header: "Category",
                    accessor: (cellProps: any) => (
                        <div className="d-flex align-items-center gap-2">
                            <div className="flex-grow-1 ms-2 user_name">{cellProps.category}</div>
                        </div>
                    ),
                    disableFilters: true,
                    filterable: true,
                },
                {
                    Header: "Action",
                    accessor: (cellProps: any) => (
                        <div className="d-flex gap-2">
                            <Button
                                variant="soft-danger"
                                size="sm"
                                onClick={() => handleDeleteClick(cellProps)}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="soft-info"
                                size="sm"
                                onClick={() => handleEditClick(cellProps)}
                            >
                                Edit
                            </Button>
                        </div>
                    ),
                    disableFilters: true,
                    filterable: true,
                },
            ],
            []
        );
    
    
    
        
    
        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <Card id="invoiceList">
                            <Card.Header className="border-0">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="d-flex gap-2 flex-wrap">
                                            {isMultiDeleteButton && (
                                                <Button variant="danger" className="btn-icon">
                                                    <i className="ri-delete-bin-2-line"></i>
                                                </Button>
                                            )}
                                            <Button variant="primary" onClick={handleAddClick}>
                                                <i className="ri-add-line align-bottom me-1"></i> Add Art Mediums
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
    
                            <Card.Body className="bg-soft-light border border-dashed border-start-0 border-end-0">
                                <Row className="g-3">
                                    <Col xxl={5} sm={12}>
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="form-control search bg-light border-light"
                                                placeholder="Search..." 
                                                value={searchTerm}
                                                onChange={handleSearchChange} 
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
    
                            <Card.Body className="p-0">
                          
                                <TableContainer
                                    columns={columns}
                                    data={ListView}
                                    sellerListss={ListView}
                                    iscustomPageSize={false}
                                    isBordered={false}
                                    customPageSize={perPageData}
                                    className="custom-header-css"
                                    tableClass="table-centered align-middle table-nowrap mb-0"
                                    theadClass="text-muted"
                                    SearchPlaceholder="Search Products..."
                                />
                           
                           {noData && (
                                <div className="text-center py-4">
                                    <div className="avatar-md mx-auto mb-4">
                                        <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                            <i className="bi bi-search"></i>
                                        </div>
                                    </div>
                                    <h5 className="mt-2"> No Result Found</h5>
                                </div>
                            )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
    
           
                <Modal show={showAddModal} onHide={handleClose}>
                    <Modal.Header className="px-4 pt-4" closeButton>
                        <h5 className="modal-title">Add Art Mediums</h5>
                    </Modal.Header>
                    <Form onSubmit={formikAdd.handleSubmit} className="tablelist-form">
                        <Modal.Body className="p-4">
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="name">Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            id="name"
                                            onChange={formikAdd.handleChange}
                                            onBlur={formikAdd.handleBlur}
                                            value={formikAdd.values.name}
                                            isInvalid={formikAdd.touched.name && !!formikAdd.errors.name}
                                        />
                                        {formikAdd.touched.name && formikAdd.errors.name ? (
                                            <Form.Control.Feedback type="invalid">
                                                {formikAdd.errors.name}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="category">Category</Form.Label>
                                        <Form.Control
                                            name="category"
                                            id="category"
                                            onChange={formikAdd.handleChange}
                                            onBlur={formikAdd.handleBlur}
                                            value={formikAdd.values.category}
                                            isInvalid={formikAdd.touched.category && !!formikAdd.errors.category}
                                        />
                                        {formikAdd.touched.category && formikAdd.errors.category ? (
                                            <Form.Control.Feedback type="invalid">
                                                {formikAdd.errors.category}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit">
                                Add
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
    
                <Modal show={showEditModal} onHide={handleClose}>
                    <Modal.Header className="px-4 pt-4" closeButton>
                        <h5 className="modal-title">Edit Art Mediums</h5>
                    </Modal.Header>
                    <Form onSubmit={formikEdit.handleSubmit} className="tablelist-form">
                        <Modal.Body className="p-4">
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="Name">Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            id="name"
                                            onChange={formikEdit.handleChange}
                                            onBlur={formikEdit.handleBlur}
                                            value={formikEdit.values.name}
                                            isInvalid={formikEdit.touched.name && !!formikEdit.errors.name}
                                        />
                                        {formikEdit.touched.name && formikEdit.errors.name ? (
                                            <Form.Control.Feedback type="invalid">
                                                {formikEdit.errors.name}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="Category">Category</Form.Label>
                                        <Form.Control
                                            name="category"
                                            id="category"
                                            onChange={formikEdit.handleChange}
                                            onBlur={formikEdit.handleBlur}
                                            value={formikEdit.values.category}
                                            isInvalid={formikEdit.touched.category && !!formikEdit.errors.category}
                                        />
                                        {formikEdit.touched.category && formikEdit.errors.category ? (
                                            <Form.Control.Feedback type="invalid">
                                                {formikEdit.errors.category}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit">
                                Save Changes
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
    
                {/* Delete Confirmation Modal */}
                <Modal show={modal_DeleteConfirm} onHide={handleClose}>
                    <Modal.Header className="px-4 pt-4" closeButton>
                        <h5 className="modal-title">Delete Art Mediums</h5>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <p>Are you sure you want to delete this item?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDeleteConfirm}>
                            Delete
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    };
    
 
    

export default ArtMediumsListTable;