import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface SuccessModalProps {
    show: boolean;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onClose }) => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/artworks/collections');
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Collections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Collections created successfully!</p>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-end'>
                <div>
                    <a href="/artworks/addcollection" className="btn btn-primary me-2" id="addproduct-btn">
                        <i className="ri-add-line align-bottom me-1"></i>Add Collection
                    </a>
                </div>
                <Button variant="success" onClick={handleBackToHome}>
                    Back to home
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessModal;
