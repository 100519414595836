
import { Navigate } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import Dd from "pages/art"
import Editartist from "pages/Editartist"
//Product
import GridView from "pages/Products/GridView";
import Overview from "pages/Products/Overview";
// import CreateProduct from "pages/Products/CreateProduct/CreateProduct";
import Categories from "pages/Products/Categories";
import SubCategories from "pages/Products/SubCategories";
// Orders
import OrdersListView from "pages/Orders/ListView"
import OrdersOverview from "pages/Orders/Overview"
// surface


// Calender
import Calendar from "pages/Calendar"
// Customers
import CustomersListView from "pages/Customers/ListView"
// Sellers
import SellersListView from "pages/Sellers/ListView"
// Partners
import PartnersListView from "pages/Partners/ListView"
import UsersrolesListView from "pages/userroles/ListView"
// Invoice
import InvoiceList from "pages/Invoices/InvoiceList"
import InvoiceDetails from "pages/Invoices/InvoiceDetails"
import CreateInvoice from "pages/Invoices/CreateInvoice"
// User List
import UsersList from "pages/UsersList"
// Shipping
import Shipments from "pages/Shipping/Shipments"
import ShippingList from "pages/Shipping/ShippingList"
// Coupons
import Coupons from "pages/Coupons"
//Review & Rating
import ReviewRating from "pages/Reviews-Rating";
//Brands
import Brands from "pages/Brands";
//statistics
import Statistics from "pages/Statistics";
// Localization
import Transactions from "pages/Localization/Transactions";
import CurrencyRates from "pages/Localization/CurrencyRates";
// Accounts
import MyAccount from "pages/Accounts/MyAccount";
import Settings from "pages/Accounts/Settings";
import SignUp from "pages/Accounts/AuthenticationInner/SignUp";
import SignIn from "pages/Accounts/AuthenticationInner/SignIn";
import PasswordReset from "pages/Accounts/AuthenticationInner/PasswordReset";
import PasswordCreate from "pages/Accounts/AuthenticationInner/PasswordCreate";
import SuccessMessage from "pages/Accounts/AuthenticationInner/SuccessMessage";
import TwoStepVerify from "pages/Accounts/AuthenticationInner/TwoStepVerify";
import BasicLogout from "pages/Accounts/AuthenticationInner/Logout";
import Error404 from "pages/Accounts/AuthenticationInner/Error404";
import Error500 from "pages/Accounts/AuthenticationInner/Error500";
import ComingSoon from "pages/Accounts/AuthenticationInner/ComingSoon";

import Recentorders from "pages/Dashboard/RecentOrders";
import Artist from "pages/Dashboard/Artist";
// Authentication
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import UserProfile from "pages/Authentication/user-profile";
import ArtistGridView from "pages/Artists/ViewArtist";
import AddArtist from "pages/Artists/AddArtist";
//configurations//
import ArtSubjectList from "pages/Configurations/Artsubject/List";
import Addartstyle from "pages/Configurations/Artstyles"
import ArtPalletsListTable from "pages/Configurations/Palletes"
import ArtWidgetsListTable from "pages/Configurations/Widegets"
import ArtType from "pages/Configurations/Arttype"


import ArtStatesListTable from "pages/Configurations/States"

import Mediums from "pages/Configurations/Mediums"
import ArtworksPrintCriteria from "pages/Configurations/ArtworksPrintCriteria"
import ArtPrintgalleryListTable from "pages/Configurations/PrintGalleryWrap"

import Categoriess from "pages/Configurations/Catgories"


import  Artsurface  from "pages/Configurations/Artsurface"
import Artworkdetails from "pages/Artworkdetails"
import AddArtwoks from "pages/Artwoks/AddArtwoks";
import  Editartworkimage from "pages/Artworkdetails/Edit artworkimage"
import Editartwork from "pages/Editartwork/index"

//collections//
import Collections from "pages/Products/Collectionslist"
import Addcolection from "pages/Collections/AddCollection"
// import Addcollections from "pages/Collections/Addcollections"

import Editcollection from "pages/Collections/Editcollections/index"
import Artworklist from "pages/Products/Artworklist/index"


const authProtectedRoutes = [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/artworks/artworks", component: <GridView /> },
    { path: "/artworks/artwork-list", component: <Artworklist /> },

    { path: "/artworks/collections", component: <Collections /> },


    { path: "/artworks/artworks-v1", component: <AddArtwoks/> },
    { path: "/artworks/edit-artwork", component: <Editartwork/> },

//collections//

{ path: "/artworks/collections", component: <Collections /> },
{ path: "/artworks/addcollection", component: <Addcolection/> },
{ path: "/artworks/editcollections", component: <Editcollection/> },










    { path: "/product-overview", component: <Overview /> },
    // { path: "/artworks/create", component: <CreateProduct /> },
    { path: "/artworks/artworks-v1", component: <AddArtwoks/> },
    { path: "/artworks/categories", component: <Categories /> },
    { path: "/artworks/sub-categories", component: <SubCategories /> },
    { path: "/artworks/arworkdetails", component: <Artworkdetails /> },
    { path: "/artworks/editartwork-image", component: < Editartworkimage/> },
    // Orders
    { path: "/orders-list-view", component: <OrdersListView /> },
    { path: "/orders-overview", component: <OrdersOverview /> },
    // Customer
    { path: "/user/customers", component: <CustomersListView /> },
    { path: "/recent-orders", component: <Recentorders /> },
    { path: "/user/artists", component: <ArtistGridView LinkToSetting={"/user/artists/add-artist"} /> },
    { path: "/recent-artist", component: <Artist /> },
    // Admin
    { path: "/user/admin", component: <SellersListView /> },
    // Partners
    { path: "/user/partners", component: <PartnersListView /> },
    { path: "/artist/edit", component: <Editartist /> },
    { path: "/user/role", component: <UsersrolesListView /> },
    // Invoice
    { path: "/invoices-list", component: <InvoiceList /> },
    { path: "/invoices-details", component: <InvoiceDetails /> },
    { path: "/invoices-create", component: <CreateInvoice /> },
    // User List
    { path: "/users-list", component: <UsersList /> },
    // Shipping
    { path: "/shipping-list", component: <ShippingList /> },
    { path: "/shipments", component: <Shipments /> },
    // Coupons
    { path: "/coupons", component: <Coupons /> },
    { path: "/calendar", component: <Calendar /> },
    //Review & Rating
    { path: "/reviews-ratings", component: <ReviewRating /> },
    { path: "/reviewsdd", component: <Dd /> },
    //Review & Rating
    { path: "/brands", component: <Brands /> },
    //statistics
    { path: "/statistics", component: <Statistics /> },
    // Localization
    { path: "/transactions", component: <Transactions /> },
    { path: "/currency-rates", component: <CurrencyRates /> },
    // Accounts
    { path: "/account", component: <MyAccount /> },
    { path: "/settings", component: <Settings /> },
    { path: "/user/artists/add-artist", component: <AddArtist/>},

    // Configurations Addartstyle Mediums ArtStatesListTable ArtworksPrintCriteria
    { path: "/configurations/art-subjects", component: <ArtSubjectList /> },

    { path: "/configurations/art-artstyle", component: <Addartstyle /> },
    { path: "/configurations/art-pallets", component: <ArtPalletsListTable /> },

    { path: "/configurations/art-widgets", component: <ArtWidgetsListTable /> },

    { path: "/configurations/states", component: <ArtStatesListTable/> },

  
    { path: "/configurations/arttype", component: <ArtType/> },
    // // { path: "/configurations/arttype", component: <ArtType/> },ArtPrintgalleryListTable   
    
    { path: "/configurations/surfaces", component: < Artsurface/> },
    { path: "/configurations/mediums", component: < Mediums/> },
    { path: "/configurations/printcriteria", component: <ArtworksPrintCriteria/> },
    { path: "/configurations/printgallery", component: <ArtPrintgalleryListTable/> },
    { path: "/configurations/category", component: <Categoriess/> },











    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name





    { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
    { path: "*", component: <Navigate to="/dashboard" /> },
    { path: "/user-profile", component: <UserProfile /> },
];

const publicRoutes = [

    // Authentication
    { path: "/login", component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/register", component: <Register /> },
    { path: "/forgot-password", component: <ForgotPassword /> },

    // AuthenticationInner
    { path: "/auth-signup-basic", component: <SignUp /> },
    { path: "/auth-signin-basic", component: <SignIn /> },
    { path: "/auth-pass-reset-basic", component: <PasswordReset /> },
    { path: "/auth-pass-change-basic", component: <PasswordCreate /> },
    { path: "/auth-success-msg-basic", component: <SuccessMessage /> },
    { path: "/auth-twostep-basic", component: <TwoStepVerify /> },
    { path: "/auth-logout-basic", component: <BasicLogout /> },
    { path: "/auth-404", component: <Error404 /> },
    { path: "/auth-500", component: <Error500 /> },
    { path: "/coming-soon", component: <ComingSoon /> },

];

export { authProtectedRoutes, publicRoutes };