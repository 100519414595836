import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
interface FormValues {
    formik: any;
}

const MainAddress: React.FC<FormValues> = ({ formik }) => {
    const { values, errors, touched, handleChange } = formik;


    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16"> Main Address</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                            <Col lg={6}>
                                        <Form.Group controlId="adressInput">
                                            <Form.Label>Adress <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Adress"
                                                placeholder="Enter  Adress"
                                                value={values.Adress}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Adress && touched.Adress}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Adress}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                            <Col lg={6}>
                                        <Form.Group controlId="countryInput">
                                            <Form.Label>Country <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="country"
                                                placeholder="Enter country"
                                                value={values.country}
                                                onChange={handleChange}
                                                isInvalid={!!errors.country && touched.country}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="cityInput">
                                            <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                placeholder="Enter city"
                                                value={values.city}
                                                onChange={handleChange}
                                                isInvalid={!!errors.city && touched.city}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {/*<Col lg={6}> */}
                                    <Col lg={6}>
                                        <Form.Group controlId="zipCodeInput">
                                            <Form.Label>Zip Code <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zip_code"
                                                placeholder="Enter zip code"
                                                value={values.zip_code}
                                                onChange={handleChange}
                                                isInvalid={!!errors.zip_code && touched.zip_code}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.zip_code}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                               
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MainAddress;