
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Modal } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import Select, { MultiValue } from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface OptionType {
  value: string;
  label: string;
}

const Collections: React.FC = () => {
  document.title = 'Product Grid | Artflute + Admin React Template';
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [collectionslength, setCollectionslength] = useState<number>(-1);
  const [sellerList, setSellerList] = useState<any[]>([]);
  const [artistList, setArtistList] = useState<OptionType[]>([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string | number>('');
  const [artistFilter, setArtistFilter] = useState<MultiValue<OptionType>>([]);
  const [availabilityFilter, setAvailabilityFilter] = useState<MultiValue<OptionType>>([]);
  const [catFilter, setCatFilter] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const perPageData = 10;


  
  useEffect(() => {
    const fetchCollectionLength = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artworks_paginations`);
        setCollectionslength(response.data);
      } catch (error) {
        console.error('Error fetching collections length', error);
      }
    };
    fetchCollectionLength();
  }, []);

  const fetchSellerList = async (page: number) => {
    setLoader(true);
    try {
        const tokenString = localStorage.getItem('authUser');
        let token = '';

        if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
        }

        const availability = {
            Is_available_for_original_sale: availabilityFilter.some(option => option.value === 'Original Sale') ? 'yes' : '',
            Is_available_for_print_sale: availabilityFilter.some(option => option.value === 'Print Sale') ? 'yes' : '',
            Is_vailable_for_desktop_art: availabilityFilter.some(option => option.value === 'Desktop Art') ? 'yes' : ''
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artworks`,
            {
                status: statusFilter,
                availability,
                artist_name: artistFilter.map(option => option.label),
                page: page,
                limit: perPageData
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (Array.isArray(response.data)) {
            setSellerList(response.data);
        } else {
            console.error("API response is not an array:", response.data);
            setSellerList([]);
        }
    } catch (error) {
        console.error("Error fetching seller list:", error);
        setSellerList([]);
    } finally {
        setLoader(false);
    }
};
  const fetchArtistList = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artist_name`);

      if (Array.isArray(response.data)) {
        const formattedOptions: OptionType[] = response.data.map((artist: any) => ({
          value: artist.id.toString(),
          label: artist.name
        }));
        setArtistList(formattedOptions);
      } else {
        console.error("API response is not an array:", response.data);
        setArtistList([]);
      }
    } catch (error) {
      console.error("Error fetching artist list:", error);
      setArtistList([]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchSellerList(currentPage);
  }, [statusFilter, availabilityFilter, artistFilter, currentPage]);


  useEffect(() => {
    fetchArtistList();
  }, []);

  const handleArtistFilterChange = (selectedOptions: MultiValue<OptionType>) => {
    setArtistFilter(selectedOptions || []);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setStatusFilter(value === '' ? '' : parseInt(value, 10));
  };

  const handleAvailabilityFilterChange = (selectedOptions: MultiValue<OptionType>) => {
    setAvailabilityFilter(selectedOptions || []);
  };

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedItem) {
      console.log('Deleting item:', selectedItem);

      setShowDeleteModal(false);
      setLoader(true);

      try {
        const tokenString = localStorage.getItem('authUser');
        let token = '';

        if (tokenString) {
          const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
          token = tokenObject.token;
        }

        await axios.post(
          `${process.env.REACT_APP_API_BASEURL}/auth/delete_artwork`,
          { id: selectedItem },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }
          }
        );

        // Refresh list after deletion
        fetchSellerList(currentPage);
      } catch (error) {
        console.error('Error deleting item:', error);
      } finally {
        setLoader(false);
        setShowDeleteModal(false);
      }
    }
  };

  console.log(sellerList, "sellerListsellerListsellerListsellerListsellerList")
  const filteredSellerList = sellerList.filter(item => {
    const matchesSearchTerm = item.Title?.toLowerCase().includes(searchTerm.toLowerCase()) || !searchTerm;
    const matchesStatus = statusFilter !== ''
      ? item.Status === statusFilter
      : true;

    const matchesAvailability = availabilityFilter.length > 0 ? availabilityFilter.some(option => {
      switch (option.value) {
        case 'Original Sale':
          return item.Is_available_for_original_sale?.toLowerCase() === 'yes';
        case 'Print Sale':
          return item.Is_available_for_print_sale?.toLowerCase() === 'yes';
        case 'Desktop Art':
          return item.Is_available_for_desktop_art?.toLowerCase() === 'yes';
        default:
          return false;
      }
    }) : true;

    const matchesArtist = artistFilter.length > 0 ? artistFilter.some(option => option.label === item.artist_name) : true;

    return matchesSearchTerm && matchesStatus && matchesAvailability && matchesArtist;
  }).slice((currentPage - 1) * perPageData, currentPage * perPageData);

  const pageNumbers = Array.from({ length: Math.ceil(collectionslength / perPageData) }, (_, i) => i + 1);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length));
  };

  



  const handleedit=(id:any)=>{
    navigate (`/artworks/edit-artwork?id=${id}`)
  }

  const handleViewDetailsClick = (id: any) => {
    navigate(`/artworks/arworkdetails?id=${id}`);
  };


console.log(sellerList, "filteredSellerListfilteredSellerListfilteredSellerListfilteredSellerList")
  console.log(filteredSellerList, "filteredSellerListfilteredSellerListfilteredSellerListfilteredSellerList")
  console.log(JSON.stringify(filteredSellerList, null, 2), "Response data");
  console.log(currentPage, "currentPagecurrentPagecurrentPagecurrentPagecurrentPagecurrentPage")
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Collections" pageTitle="Collections" />
        <Row>
          <Col xl={12} lg={12}>
            <div className="card">
              <div className="card-body">
                <div className="g-3 row">
                  <Col lg={3}>
                    <label htmlFor="availabilityFilter" className="text-muted text-uppercase fs-13 mb-3">Availability</label>
                    <Select
                      id="availabilityFilter"
                      isMulti
                      options={[
                        { value: 'Original Sale', label: 'Original Sale' },
                        { value: 'Print Sale', label: 'Print Sale' },
                        { value: 'Desktop Art', label: 'Desktop Art' }
                      ]}
                      value={availabilityFilter}
                      onChange={handleAvailabilityFilterChange}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>

                  <Col lg={3}>
                    <label htmlFor="artistFilter" className="text-muted text-uppercase fs-13 mb-3">Artists</label>
                    <Select
                      id="artistFilter"
                      isMulti
                      options={artistList}
                      value={artistFilter}
                      onChange={handleArtistFilterChange}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select artists..."
                    />
                  </Col>

                  <Col lg={3}>
                    <label htmlFor="statusFilter" className="text-muted text-uppercase fs-13 mb-0 mb-3">Status</label>
                    <select
                      id="statusFilter"
                      className="form-select"
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                    >
                      <option value="">All</option>
                      <option value="1">Waiting for approval</option>
                      <option value="2">Approved</option>
                      <option value="3">Sold Out</option>
                      <option value="4">Not approved</option>
                      <option value="5">Enable</option>
                      <option value="6">Available For Commission Work</option>
                      <option value="7">Deleted</option>
                      <option value="8">Hidden</option>
                    </select>
                  </Col>

                  <Col lg={3}>
                    <label htmlFor="searchTerm" className="text-muted text-uppercase fs-13 mb-3">Search</label>
                    <input
                      id="searchTerm"
                      type="text"
                      className="form-control"
                      placeholder="Search by Title"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={12}>
            <Card>
              <Card.Body>
                <table className="table align-middle table-nowrap" id="customerTable">
                  <thead className="text-muted table-light">
                    <tr>
                      <th scope="col">
                        <input type="checkbox" className="form-check-input" />
                      </th>
                      <th scope="col">Title</th>
                      <th scope="col">Category</th>
                      <th scope="col">Availability</th>
                      <th scope="col">Status</th>
                      <th scope="col">Orginal price</th>
                      <th scope="col">Artist</th>
                      <th scope="col">published at</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
  {sellerList.length > 0 ? (
    sellerList
      .filter((item: any) => {
       

        const matchesSearchTerm = item.Title?.toLowerCase().includes(searchTerm.toLowerCase()) || !searchTerm;
        const matchesStatus = statusFilter !== ''
          ? item.Status === statusFilter
          : true;
    
        const matchesAvailability = availabilityFilter.length > 0 ? availabilityFilter.some(option => {
          switch (option.value) {
            case 'Original Sale':
              return item.Is_available_for_original_sale?.toLowerCase() === 'yes';
            case 'Print Sale':
              return item.Is_available_for_print_sale?.toLowerCase() === 'yes';
            case 'Desktop Art':
              return item.Is_available_for_desktop_art?.toLowerCase() === 'yes';
            default:
              return false;
          }
        }) : true;
    
        const matchesArtist = artistFilter.length > 0 ? artistFilter.some(option => option.label === item.artist_name) : true;
    
        return matchesSearchTerm && matchesStatus && matchesAvailability && matchesArtist;
    
      })
      .map((item: any) => (
        <tr key={item.id}>
          <td>
            <input
              className="form-check-input"
              type="checkbox"
              value={item.id}
            />
          </td>
          <td>{item.Title}</td>
          <td>{item.Category_name}</td>
          <td>
            <span>Original Sale: {item.Is_available_for_original_sale === 'yes' ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" className="bi bi-check" viewBox="0 0 16 16">
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            )}</span>
            <span>
              Print Sale: {item.Is_available_for_print_sale === 'yes' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" className="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              )}
            </span>
          </td>
          <td>
            {item.Status === 1 ? 'Waiting for approval' :
              item.Status === 2 ? 'Approved' :
              item.Status === 3 ? 'Sold Out' :
              item.Status === 4 ? 'Not approved' :
              item.Status === 5 ? 'Enable' :
              item.Status === 6 ? 'Available For Commission Work' :
              item.Status === 7 ? 'Deleted' :
              item.Status === 8 ? 'Disabled' : item.Status}
          </td>
          <td>{item.Originals_price}</td>
          <td>{item.artist_name}</td>
          <td>{item.published_at}</td>
          <td>
            <div className="d-flex gap-2">
              <Button className="btn btn-sm btn-soft-secondary" onClick={() => handleViewDetailsClick(item.id)}>View</Button>
              <Button className="btn btn-sm btn-soft-info" onClick={() => handleedit(item.id)}>Edit </Button>
              {/* <Button className="btn btn-sm btn-soft-danger">Remove </Button> */}
              <Button
    className="btn btn-sm btn-soft-danger"
    onClick={() => handleDeleteClick(item.id)}
  >
    Remove
  </Button>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={6}>No collections found.</td>
      </tr>
    )}
</tbody>

                </table>

                <div className="pagination pagination-separated mb-0 justify-content-center justify-content-sm-end">
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Button className="page-link" onClick={handlePrevPage}>
                      Previous
                    </Button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <Button className="page-link" onClick={() => setCurrentPage(number)}>
                        {number}
                      </Button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                    <Button className="page-link" onClick={handleNextPage}>
                      Next
                    </Button>
                  </li>
                </ul>
              </nav>
            </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Collections;

