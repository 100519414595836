import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
interface FormValues {
    formik: any;
}

const Bankdetails: React.FC<FormValues> = ({ formik }) => {
    const { values, errors, touched, handleChange } = formik;


    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16"> Bankdetails</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                            <Col lg={6}>
                            <Form.Group controlId="accountHolderNameInput">
    <Form.Label>Account Holder's Name <span className="text-danger">*</span></Form.Label>
    <Form.Control
        type="text"
        name="accountHolderName" // Corrected name to be consistent
        placeholder="Enter Account Holder's Name"
        value={values.accountHolderName} // Corrected value to match the form field
        onChange={handleChange}
        isInvalid={!!errors.accountHolderName && touched.accountHolderName} // Corrected validation logic
    />
    <Form.Control.Feedback type="invalid" className='text-danger'>
        {errors.accountHolderName} // Corrected error display
    </Form.Control.Feedback>
</Form.Group>

                                    </Col>
                            <Col lg={6}>
                            <Form.Group controlId="countryInput">
  <Form.Label>
    Account Number <span className="text-danger">*</span>
  </Form.Label>
  <Form.Control
    type="text"
    name="accountNumber"
    placeholder="Enter Account Number"
    value={values.accountNumber}
    onChange={handleChange}
    isInvalid={!!errors.accountNumber && touched.accountNumber}
  />
  <Form.Control.Feedback type="invalid" className="text-danger">
    {errors.accountNumber}
  </Form.Control.Feedback>
</Form.Group>

                                    </Col>
                                    <Col lg={6}>
                                    <Form.Group controlId="bankIfscCodeInput">
    <Form.Label>Bank IFSC Code <span className="text-danger">*</span></Form.Label>
    <Form.Control
        type="text"
        name="bankifsccode"  
        placeholder="Enter Bank IFSC Code"
        value={values.bankifsccode}  
        onChange={handleChange}
        isInvalid={!!errors.bankifsccode && touched.bankifsccode}  
    />
    <Form.Control.Feedback type="invalid" className="text-danger">
        {errors.bankifsccode} 
    </Form.Control.Feedback>
</Form.Group>

                                    </Col>
                                    {/*<Col lg={6}> */}
                                    <Col lg={6}>
                                    <Form.Group controlId="branchNameInput">
    <Form.Label>Branch Name <span className="text-danger">*</span></Form.Label>
    <Form.Control
        type="text"
        name="branchName" // Changed the name to be consistent
        placeholder="Enter Branch Name"
        value={values.branchName} // Corrected to access the right form value
        onChange={handleChange}
        isInvalid={!!errors.branchName && touched.branchName} // Corrected the validation logic
    />
    <Form.Control.Feedback type="invalid" className='text-danger'>
        {errors.branchName} // Corrected error message display
    </Form.Control.Feedback>
</Form.Group>

                                    </Col>
                               
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Bankdetails;