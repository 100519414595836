

import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import Editcollections1 from 'pages/Collections/Editcollections/Editcollection';
import axios from 'axios';
import SuccessModal from './succesFullmodal';
import { useDebounce } from 'use-debounce';
import { useLocation, useNavigate } from 'react-router-dom';

const Editcollections: React.FC = () => {
  const validationSchema = Yup.object().shape({
    // Add necessary validation fields here
  });

  const [loader, setLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string | number>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [sellerList, setSellerList] = useState<any[]>([]);
  const [previouscollectionslist, setpreiouscolletionsdata] = useState<any>({});

  const [isHeaderNavigationsFilter, setIsHeaderNavigationsFilter] = useState<string | number>('');
  const perPageData = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const { data: collectionsid } = location.state || {};
  const id = collectionsid;

  const fetchSellerList = async (page: number) => {
    setLoader(true);
    try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString.replace('Bearer ', '')).token : '';
      const headernavigations = isHeaderNavigationsFilter || '';

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/get_all_collections`,
        {
          status: statusFilter,
          searchTerm: debouncedSearchTerm,
          headernavigations: headernavigations,
          page: page,
          limit: perPageData
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (Array.isArray(response.data)) {
        setSellerList(response.data);
      } else {
        setSellerList([]);
      }
    } catch (error) {
      console.error('Error fetching seller list:', error);
      setSellerList([]);
    } finally {
      setLoader(false);
    }
  };

  const fetchcollectionsdetailsList = async () => {
    setLoader(true);
    try {
      const tokenString = localStorage.getItem('authUser');
      let token = '';

      if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/auth/get_collectionsdetails_byid`,
        {
          params: { id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setpreiouscolletionsdata(response.data);
    } catch (error) {
      console.error('Error fetching collection details:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchcollectionsdetailsList();
    }
  }, [id]);
console.log(previouscollectionslist.Thumbnail_image, "previouscollectionslist.Thumbnail_imagepreviouscollectionslist.Thumbnail_imagepreviouscollectionslist.Thumbnail_image" )
  const getInitialValues = () => ({
    Thumbnail_image: previouscollectionslist.Thumbnail_image || '',
    Name: previouscollectionslist.Name || '',
    Slug: previouscollectionslist.Slug || '',
    Sort: previouscollectionslist.Sort || '',
    Selection: previouscollectionslist.Selection || '',
    Artist_id: previouscollectionslist.Artist_id || '',
    Seo_description: previouscollectionslist.Seo_description || '',
    Seo_title: previouscollectionslist.Seo_title || '',
    Orientation_id: previouscollectionslist.Orientation_id || '',
    Size_id: previouscollectionslist.Size_id || '',
    Status: previouscollectionslist.Status || '',
    Show_in_header_navigation: previouscollectionslist.Show_in_header_navigation || '',
    Collection_for: previouscollectionslist.Collection_for || '',
    Schema_code: previouscollectionslist.Schema_code || '',
    FAQ: previouscollectionslist.FAQ || '',
    Description: previouscollectionslist.Description || '',
    Category_id: previouscollectionslist.Category_id || '',
    Category_name: previouscollectionslist.Name || '',
    Medium_id: previouscollectionslist.Medium_id || '',
    Art_style_id: previouscollectionslist.Art_style_id || '',
    Art_Subject_id: previouscollectionslist.Art_Subject_id || '',
    Surface_id: previouscollectionslist.Surface_id || '',
    Artwork_id: previouscollectionslist.Artwork_id || '',
    Palette_id: previouscollectionslist.Name || '',
    Price_id: previouscollectionslist.Price_id || '',
    id: id || '',
  });

  const [artWorkId, setArtWorkId] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString)?.token : '';
      const endpoint = '/auth/edit_art_collections';

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.user) {
        setArtWorkId(response.data.user.Artwork_id);
        resetForm();
        fetchSellerList(1);
        setShowSuccessModal(true);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  return (
    <Row>
      <Col md={12}>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => (
            <Form>
              <Editcollections1 formik={formik} artwork_Id={artWorkId} />
              <div className="d-flex justify-content-end mt-4">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <SuccessModal show={showSuccessModal} onClose={handleModalClose} />
      </Col>
    </Row>
  );
};

export default Editcollections;

