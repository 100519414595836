import BreadCrumb from "Common/BreadCrumb";
import EditStepperWizard from "Common/Editstepper";
import React from "react";
import {  Container } from "react-bootstrap";

function AddArtwoks() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Artwork" pageTitle="Artwork" />
          <EditStepperWizard />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddArtwoks;