import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

interface CreateProductStep1Props {
    formik: any;
    artWorkdata:any
    artWorkmediums_id:any
    artWorktitle:any
    ArtWorkcategory_id:any
    artWorkdescription:any
}

const SeoStep3: React.FC<CreateProductStep1Props> = ({ formik, artWorkdata, artWorkmediums_id,artWorktitle,artWorkdescription,ArtWorkcategory_id }) => {
    document.title = "Categories | Artflute + Admin React Template"
    const [loader, setLoader] = useState<boolean>(false);

    const [getArtist, setGetArtist] = useState<{ value: string, label: string }[]>([]);
    


    const [getcategory, setGetCategory] = useState<{ value: string, label: string }[]>([]);
    const [medianslist, setmediansList] = useState<any[]>([]);
    useEffect(() => {
        console.log('artWorkdata in seo:', artWorkdata);
        
        console.log('artWorkmediums_id in seo:', artWorkmediums_id);
        console.log('artWorktitle in seo:', artWorktitle);
        console.log('artWorkdescription in seo:', artWorkdescription);
        
      }, [artWorkdata, artWorkmediums_id,artWorktitle,artWorkdescription,ArtWorkcategory_id]);
    

    const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = formik;
    
  const fetchartmediansList = async () => {
    setLoader(true);
    try {
      

        const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_artmedians_dropdown`,
         
        );

        setmediansList(response.data);

    } catch (error) {
        console.error("Error fetching seller list:", error);
    } finally {
        setLoader(false);
    }
};

useEffect(() => {
   
    fetchartmediansList(); 

}, []);
    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artist_name`);
                console.log("kk", response.data)
                if (response.data && Array.isArray(response.data) && response.data[0] !== null) {
                    const artists = response.data.map((artist: { id: string, name: string }) => ({
                        value: artist.id,
                        label: artist.name
                    }));
                    setGetArtist(artists);
                } else {
                    console.warn("No artist data available");
                }
            } catch (error) {
                console.error("Error fetching artist names", error);
            }
        };

        fetchArtists();
    }, []);




    useEffect(() => {
        const fetchcategory = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artcategoryname`);
                console.log("kk", response.data)
                if (response.data && Array.isArray(response.data) && response.data[0] !== null) {
                    const artists = response.data.map((artist: { id: string, name: string }) => ({
                        value: artist.id,
                        label: artist.name
                    }));
                    setGetCategory(artists);
                } else {
                    console.warn("No artist data available");
                }
            } catch (error) {
                console.error("Error fetching artist names", error);
            }
        };

        fetchcategory();
    }, []);



    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

console.log(JSON.stringify(getArtist, null, 2), "getArtistgetArtistgetArtistgetArtist");

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Seo</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                        <Col lg={12}>
                                        <Form.Group className="mt-3" controlId="seo_title">
    <Form.Label>SEO Title</Form.Label>
    <Form.Control
        type="text"
        name="seo_title"
        placeholder="Enter SEO Title"
        value={
            values.seo_title === ''
                ? '' 
                : values.seo_title ||
                    `${artWorktitle || ''} by ${(getArtist?.find(option => option.value == artWorkdata)?.label || '').trim()} | 
                     ${(getcategory?.find(option => option.value == artWorkdata)?.label || '').trim()} ,
                     ${medianslist
                        ?.filter(option => 
                            artWorkmediums_id?.split(',').includes(option.id.toString())
                        )
                        .map(option => option.Label)
                        .join(', ') 
                        .trim()}`
                    .replace(/\s+/g, ' ').trim()
        }
        onChange={e => setFieldValue('seo_title', e.target.value)}
        isInvalid={!!errors.seo_title && touched.seo_title}
    />
    <Form.Control.Feedback type="invalid" className="text-danger">
        {errors.seo_title}
    </Form.Control.Feedback>
</Form.Group>
</Col>





                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_keyword">
                                                    <Form.Label>Seo Keyword </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="seo_keyword"
                                                        placeholder="Enter Seo Keyword"
                                                        value={values.seo_keyword}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.seo_keyword && touched.seo_keyword}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_keyword}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                           


                                            <Col lg={12}>
                                            <Form.Group className="mt-3" controlId="seo_description">
    <Form.Label>SEO Description</Form.Label>
    <Form.Control
        type="text"
        name="seo_description"
        placeholder="Enter SEO descriptions"
        value={
            values.seo_description === '' 
                ? '' // This allows the input to remain cleared if the user manually clears it
                : values.seo_description || 
                `${artWorktitle || ''} by ${(getArtist?.find(option => option.value == artWorkdata)?.label || '').trim()} | 
                ${(getcategory?.find(option => option.value == artWorkdata)?.label || '').trim()} ,
                ${medianslist
                   ?.filter(option => 
                       artWorkmediums_id?.split(',').includes(option.id.toString())
                   )
                   .map(option => option.Label)
                   .join(', ') 
                   .trim()}`
               .replace(/\s+/g, ' ').trim()
        }
        onChange={e => setFieldValue('seo_description', e.target.value)}
        isInvalid={!!errors.seo_description && touched.seo_description}
    />
    <Form.Control.Feedback type="invalid" className="text-danger">
        {errors.seo_description}
    </Form.Control.Feedback>
</Form.Group>

</Col>


                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_image">
                                                    <Form.Label>Seo image</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="seo_image"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "seo_image")}
                                                        isInvalid={!!errors.seo_image && touched.seo_image}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_image}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SeoStep3;