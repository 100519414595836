
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Modal } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const Collections: React.FC = () => {
  document.title = 'Product Grid | Artflute + Admin React Template';
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [sellerList, setSellerList] = useState<any[]>([]);
  const [collectionslength, setCollectionslength] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string | number>('');
  const [isHeaderNavigationsFilter, setIsHeaderNavigationsFilter] = useState<string | number>('');
  const [loader, setLoader] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [modal_DeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [deletecollections, setdeletecollections] = useState<any>(null);
  const perPageData = 10;

  useEffect(() => {
    const fetchCollectionLength = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_allcollections_paginations`);
        setCollectionslength(response.data);
      } catch (error) {
        console.error('Error fetching collections length', error);
      }
    };
    fetchCollectionLength();
  }, []);

  const fetchSellerList = async (page: number) => {
    setLoader(true);
    try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString.replace('Bearer ', '')).token : '';
      const headernavigations = isHeaderNavigationsFilter ? isHeaderNavigationsFilter : '';
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/get_all_collections`,
        {
          status: statusFilter,
          search: debouncedSearchTerm,
          headernavigations: headernavigations,
          page: page,
          limit: perPageData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (Array.isArray(response.data)) {
        setSellerList(response.data);
      } else {
        console.error('API response is not an array:', response.data);
        setSellerList([]);
      }
    } catch (error) {
      console.error('Error fetching seller list:', error);
      setSellerList([]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchSellerList(currentPage);
  }, [statusFilter, debouncedSearchTerm, isHeaderNavigationsFilter, currentPage]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setStatusFilter(value === '' ? '' : parseInt(value, 10));
  };

  const handleIsHeaderNavigationsFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setIsHeaderNavigationsFilter(value === '' ? '' : parseInt(value, 10));
  };

  const pageNumbers = Array.from({ length: Math.ceil(collectionslength / perPageData) }, (_, i) => i + 1);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length));
  };

  const handleViewDetailsClick = (id: any) => {
    navigate(`/artworks/artworkdetails?id=${id}`);
  };

  const handleEditDetailsClick = (collectionsid: any) => {
    navigate(`/artworks/editcollections?id=${collectionsid}`, { state: { data: collectionsid } });
  };
  const handleDeleteClick = (id: any) => {
    console.log(id, "selected id")
    setdeletecollections(id); 
    setModalDeleteConfirm(true);
  };
  


  const tokenString = localStorage.getItem('authUser');
  let token = '';

  if (tokenString) {
      const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
      token = tokenObject.token;
  }


  const deleteCollectionsById = async () => {
    console.log("Selected ID:", deletecollections);

    setLoader(true);
    try {
        if (deletecollections) {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/delete_art_collections`, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, 
                    },
                    params: {
                        id: deletecollections, 
                    },
                }
            );
      
            setdeletecollections(response.data);
            setModalDeleteConfirm(false);
            fetchSellerList(currentPage);
        }
    } catch (error) {
        console.error(error);
    } finally {
        setLoader(false);
    }
};





  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Collections" pageTitle="Collections" />
        <Row>
          <Col xl={12} lg={12}>
            <div className="card">
              <div className="card-body">
                <div className="g-3 row">
                  <div className="col-lg-3">
                    <label htmlFor="headerNavFilter" className="text-muted text-uppercase fs-13 mb-3">
                      Search
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      id="searchProductList"
                      placeholder="Search collections..."
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="headerNavFilter" className="text-muted text-uppercase fs-13 mb-3">
                      Header Navigation
                    </label>
                    <select
                      id="headerNavFilter"
                      className="form-select"
                      value={isHeaderNavigationsFilter}
                      onChange={handleIsHeaderNavigationsFilterChange}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="statusFilter" className="text-muted text-uppercase fs-13 mb-3">
                      Status
                    </label>
                    <select id="statusFilter" className="form-select" value={statusFilter} onChange={handleStatusFilterChange}>
                      <option value="">All</option>
                      <option value="1">Active</option>
                      <option value="2">Not Active</option>
                    </select>
                  </div>
                  <div className="col-lg-auto ms-auto col">
                    <div className="hstack gap-2">
                      <Button className="btn btn-primary">
                        <a href="/artworks/addcollection" className="d-flex align-items-center text-decoration-none text-light">
                          <i className="ri-add-line align-bottom me-1"></i>Add Collections
                        </a>
                      </Button>
                      
                  
                      <Dropdown align="end">
                        <Dropdown.Toggle variant="soft-dark" className="btn-icon arrow-none">
                          <i className="ph-dots-three-outline"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="/user/customers">Action</Dropdown.Item>
                          <Dropdown.Item href="/user/customers">Another action</Dropdown.Item>
                          <Dropdown.Item href="/user/customers">Something else here</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Card>
              <Card.Body>
                <table className="table align-middle table-nowrap" id="customerTable">
                  <thead className="text-muted table-light">
                    <tr>
                      <th>
                        <input type="checkbox" className="form-check-input" />
                      </th>
                      <th>Collections Name</th>
                      <th>Slug</th>
                      <th>Show in header navigation</th>
                      <th>Collections Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sellerList.length > 0 ? (
                      sellerList.map((user) => (
                        <tr key={user.id}>
                          <td>
                            <input className="form-check-input" type="checkbox" value={user.id} />
                          </td>
                          <td>{user.Name}</td>
                          <td>{user.Slug}</td>
                          <td>{user.Show_in_header_navigation === '1' ? 'Yes' : 'No'}</td>
                          <td>{user.Status === '1' ? 'Active' : 'Not Active'}</td>
                          <td>
                            <div className="d-flex gap-2">
                              <Button className="btn btn-sm btn-soft-secondary" onClick={() => handleViewDetailsClick(user.id)}>
                                View
                              </Button>
                              <Button className="btn btn-sm btn-soft-info" onClick={() => handleEditDetailsClick(user.id)}>
                                Edit
                              </Button>
                              <Button className="btn btn-sm btn-soft-danger" onClick={() => handleDeleteClick(user.id)}>
                                Remove
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center py-4">
                          <h5>No Results Found</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this item?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteCollectionsById}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="pagination pagination-separated mb-0 justify-content-center justify-content-sm-end">
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Button className="page-link" onClick={handlePrevPage}>
                      Previous
                    </Button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <Button className="page-link" onClick={() => setCurrentPage(number)}>
                        {number}
                      </Button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                    <Button className="page-link" onClick={handleNextPage}>
                      Next
                    </Button>
                  </li>
                </ul>
              </nav>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Collections;

