import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface SuccessModalProps {
    show: boolean;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onClose }) => (
    <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Artwork</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Artwork updated successfully!</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
    <div>
        <a href="/artworks/edit-artwork" className="btn btn-primary me-2" id="addproduct-btn">
            <i className="ri-add-line align-bottom me-1"></i>Update Artwork
        </a>
    </div>
    <Button variant="success" onClick={onClose} className="me-2">
        Back to home
    </Button>
</Modal.Footer>
    </Modal>
);

export default SuccessModal;

