import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nouislider from "nouislider-react";
import "../../../../node_modules/nouislider/distribute/nouislider.css";
import ProductSelectColor from './ProductSelectColor';
import ProductSize from './ProductSize';
import ProductDiscount from './ProductDiscount';
import ProductRating from './ProductRating';
import axios from 'axios';
import GridView from '../GridView';

const ProductCategoryFilter = () => {
    const [mincost, setMincost] = useState<number>(0);
    const [maxcost, setMaxcost] = useState<number>(2000);

    const [loader, setLoader] = useState<boolean>(false);
    const [categoryList, setCategoryList] = useState<any>([]);
    const [modalAddSeller, setModalAddSeller] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    const [statusdate, setstatusdate] = useState<any>([]);;


    //Product Filter
    const onUpdate = (value: any) => {
        setMincost(value[0]);
        setMaxcost(value[1]);
    }
    useEffect(() => {
        onUpdate([0, 2000]);
    }, []);


    

  
    return (
        <div>
            <ProductSize />
            <ProductRating />
            
        </div>
    )
}

export default ProductCategoryFilter