import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import deleteicon from 'assets/images/delete icon.png';

interface CreateProductStep1Props {
    formik: {
        values: any;
        errors: any;
        touched: any;
        setFieldValue: (field: string, value: any) => void;
        handleSubmit: () => void;
        setErrors: (errors: any) => void; // Ensure setErrors is defined
        setTouched: (touched: any) => void; // Ensure setTouched is defined
    };
}

const SupportingImageStep4: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template";

    const { errors, touched, setFieldValue, handleSubmit, values } = formik;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                const data = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                const config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                const response = await axios.request(config);
                setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    const handleRemoveItem = (arrayName: string, index: number) => {
        const updatedArray = [...values[arrayName]];
        updatedArray.splice(index, 1);
        setFieldValue(arrayName, updatedArray);
    };

    const handleAddItem = (arrayName: string) => {
        const updatedArray = [...(values[arrayName] || []), { name: '' }];
        setFieldValue(arrayName, updatedArray);
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Seo</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}
                                    >
                                        {(values.artist_education || [{ name: '' }]).map((item: any, index: number) => {
                                           
                                            const displayIndex = index + 1;
                                            return (
                                                <Row key={index}>
                                                    <Col lg={12}>
                                                        <Form.Group className='mt-3' controlId={`supporting_image_${displayIndex}`}>
                                                            <Form.Label>Supporting Image {displayIndex}</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name={`supporting_image_${displayIndex}`} 
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, `supporting_image_${displayIndex}`)}
                                                                isInvalid={!!errors[`supporting_image_${displayIndex}`] && touched[`supporting_image_${displayIndex}`]}
                                                            />
                                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                                {errors[`supporting_image_${displayIndex}`]}
                                                            </Form.Control.Feedback>
                                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                                {index === 0 ? null : (
                                                                    <Button variant="link" onClick={() => handleRemoveItem('artist_education', index)}>
                                                                        <img src={deleteicon} alt="delete" height={18} width={18} />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        <Button variant="outline-primary" onClick={() => handleAddItem('artist_education')}>
                                            + Add images
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SupportingImageStep4;
