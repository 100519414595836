import React, { useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import CreateProductStep1 from 'pages/Collections/Addcollections'; // Make sure this component handles the single step's form
import axios from 'axios';
import Collectionssuccessful from './Collectionssuccessfulmodal';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const StepperWizard: React.FC = () => {
const validationSchema = Yup.object().shape({
  // Name: Yup.string().required('Name is required'),
  // Slug: Yup.string().required('Slug width is required'),
  
});




const [loader, setLoader] = useState(false);
const [statusFilter, setStatusFilter] = useState<string | number>('');
const [searchTerm, setSearchTerm] = useState('');
const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
const [sellerList, setSellerList] = useState<any[]>([]);
    const [isHeaderNavigationsFilter, setIsHeaderNavigationsFilter] = useState<string | number>('');
  
const perPageData = 10;

const fetchSellerList = async (page: number) => {
  setLoader(true);
  try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString.replace('Bearer ', '')).token : '';
      const headernavigations = isHeaderNavigationsFilter ? isHeaderNavigationsFilter : '';
      const response = await axios.post(
          `${process.env.REACT_APP_API_BASEURL}/auth/Get_all_collections`,
          {
              status: statusFilter,
              searchTerm: debouncedSearchTerm,
              headernavigations: headernavigations,
              page: page,
              limit: perPageData
          },
          {
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          }
      );
      if (Array.isArray(response.data)) {
          setSellerList(response.data);
      } else {
          console.error("API response is not an array:", response.data);
          setSellerList([]);
      }
  } catch (error) {
      console.error("Error fetching seller list:", error);
      setSellerList([]);
  } finally {
      setLoader(false);
  }
};

const getInitialValues = () => ({
  Thumbnail_image: '',
  Name: '',
  Slug: '',
  Sort: '',
  Selection: '',
  Artist_id: '',
  Seo_description: '',
  Seo_title: '',
  Orientation_id:'',
  Size_id:'',
  Status: '',
  Show_in_header_navigation: '',
  Collection_for: '',
  Schema_code: '',
  FAQ: '',
  Description: '',
  Tags_id: '',
  Category_id: '',
  Category_name:"",
  Medium_id: '',
  Art_style_id: '',
  Art_Subject_id: '',
  Surface_id: '',
  Artwork_id:'',
  Palette_id: '',
  Price_id:'',
  id:""
});


  const [artWorkId, setArtWorkId] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const tokenString = localStorage.getItem('authUser');
      const token = tokenString ? JSON.parse(tokenString)?.token : '';

      const endpoint = '/auth/add_art_collections';

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
        values, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        if (response.data && response.data.user) {
        
          setArtWorkId(response.data.user.Artwork_id);
          
          resetForm();
          fetchSellerList(1);
        } else {
          throw new Error('Something went wrong');
        }
        setShowSuccessModal(true);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      throw new Error('Something went wrong');
    }
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  return (
    <Row>
      <Col md={12}>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => (
            <Form>
              <CreateProductStep1 formik={formik} artwork_Id={artWorkId} />
              <div className="d-flex justify-content-end mt-4">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Collectionssuccessful show={showSuccessModal} onClose={handleModalClose} />
      </Col>
    </Row>
  );
};


export default StepperWizard;
