import React, { useState } from 'react';
import { Button, Col, Form, Row, Card, Image } from 'react-bootstrap';
import { useFormik } from 'formik';

interface ArtworkItem {
  id: string;
  image_link?: string;
  high_resolution_image?: string;
  dimension_width?: string;
  dimension_height?: string;
  dimension_pixel?: string;
  artwork?: string;
}

const artworkOptions = [
  'In Quiet Reverence',
  'The Starry Night',
  'Sunset Boulevard',
  'Whispering Pines',
  'Eternal Bloom',
  'Ocean’s Embrace'
];

const EditArtworkImage: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string>(
    'https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/31_08_24_01_13_00_IMG20230825181002.jpg'
  );

  const formik = useFormik<ArtworkItem>({
    initialValues: {
      id: '',
      image_link: '',
      high_resolution_image: 'No',
      dimension_width: '',
      dimension_height: '',
      dimension_pixel: '',
      artwork: 'In Quiet Reverence',
    },
    onSubmit: (values) => {
      console.log('Form Values:', values);
      
    },
  });

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      formik.setFieldValue('image_link', imageUrl);
    }
  };

  return (
    <div style={{ paddingTop: '80px', paddingBottom: 'px' }}>
      <Row className="justify-content-center">
        <Col md={12} lg={6}>
          <Card className="border-light shadow-sm" style={{ padding: '20px' }}>
            <Card.Body>
              <h4 className="mb-3">Edit Artwork</h4>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Image <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <div className="mb-2" style={{ width: '150px' }}>
                    <Image src={selectedImage} thumbnail />
                  </div>
                  <Form.Control
                    type="file"
                    name="image_upload"
                    onChange={handleImageUpload}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    High Resolution Image <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="high_resolution_image"
                    value={formik.values.high_resolution_image}
                    onChange={formik.handleChange}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Dimension Width</Form.Label>
                  <Form.Control
                    type="text"
                    name="dimension_width"
                    placeholder="Enter width"
                    value={formik.values.dimension_width}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Dimension Height</Form.Label>
                  <Form.Control
                    type="text"
                    name="dimension_height"
                    placeholder="Enter height"
                    value={formik.values.dimension_height}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Dimension Pixel</Form.Label>
                  <Form.Control
                    type="text"
                    name="dimension_pixel"
                    placeholder="Enter pixel value"
                    value={formik.values.dimension_pixel}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Artwork <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="artwork"
                    value={formik.values.artwork}
                    onChange={formik.handleChange}
                  >
                    {artworkOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <div className="d-flex">
                  <Button type="submit" variant="primary" className="me-2">
                    Save
                  </Button>
                  <Button variant="secondary" onClick={() => console.log('Cancel clicked')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditArtworkImage;
