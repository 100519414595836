


  import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Tabs, Tab, Table, Form } from 'react-bootstrap';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'


interface ArtworkItem {
  id: string;
}

const ArtworkDetails = () => {
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState('gallery');
  const [loader, setLoader] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const [selectedItem, setSelectedItem] = useState<ArtworkItem | null>(null);
  const location = useLocation();
  const { data: id } = location.state || {};

  const navigate = useNavigate();
  console.log('Received ID:', id);

  const handleDisableArtwork = () => {
    setShowDisableModal(false);
  };

  const fetchRolesList = async () => {
    setLoader(true);
    try {
      const tokenString = localStorage.getItem('authUser');
      let token = '';

      if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artwork_by_id`,
        {
          params: { id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSellerList(response.data);
    } catch (error) {
      console.error('Error fetching seller list:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchRolesList();
  }, []);
  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };
  
  

   const handleDeleteConfirm = async () => {
    if (selectedItem) {
      console.log('Deleting item:', selectedItem);

      setShowDeleteModal(false);
      setLoader(true);

      try {
          const tokenString = localStorage.getItem('authUser');
          let token = '';

          if (tokenString) {
              const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
              token = tokenObject.token;
          }

          await axios.post(
              `${process.env.REACT_APP_API_BASEURL}/auth/delete_artwork`,
             { id: selectedItem},
              {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                  },
                
                }
              
              )

         
          fetchRolesList();
      } catch (error) {
          console.error('Error deleting item:', error);
      } finally {
          setLoader(false);
          setShowDeleteModal(false);
      }
  }
};
  
  




const handleDisableClick = (item: any) => {
  setSelectedItem(item);
  setShowDisableModal(true);
};



 const handleDisableConfirm = async () => {
  if (selectedItem) {
    console.log('Deleting item:', selectedItem);

    setShowDisableModal(false);
    setLoader(true);

    try {
        const tokenString = localStorage.getItem('authUser');
        let token = '';

        if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
        }

        await axios.post(
            `${process.env.REACT_APP_API_BASEURL}/auth/disable_artwork`,
           { id: selectedItem},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
              
              }
            
            )

       
        fetchRolesList();
    } catch (error) {
        console.error('Error deleting item:', error);
    } finally {
        setLoader(false);
        setShowDisableModal(false);
    }
}
};

const handleViewDetailsClick = (id: any) => {
  console.log('Item ID:', id);
  navigate(`/artworks/edit-artwork?id=${id}`, { state: { data: id } }); 
};

const [showAddImageModal, setShowAddImageModal] = useState(false);
const [showHighresolutionModal, setshowHighresolutionModal] = useState(false);

const [showprintavailabltyModal, setshowprintavailabltyModal] = useState(false);


const handleAddImageClick = () => {
  setShowAddImageModal(true);
};

const handleCloseAddImageModal = () => {
  setShowAddImageModal(false);
};


const handlehighresollutionsClick = () => {
  setshowHighresolutionModal(true);
};

const handleCloseAddhighresolutionsModal = () => {
  setshowHighresolutionModal(false);
};



const handleaddprintsClick = () => {
  setshowprintavailabltyModal(true);
};

const handleCloseAddprintModal = () => {
  setshowprintavailabltyModal(false);
};
  
  
    return (
      <div className="artwork-details">
        <Row>
          {/* Artwork Image Column */}
          <Col md={4} lg={4}>
            {sellerList.map((item:any) => (
              <div className="box-body" key={item.id}>
                <img
                  src={item.Artwork_image}
                  alt="Artwork Thumbnail"
                  style={{ width: '100%' }}
                />
              </div>
            ))}
          </Col>
  
          {/* Artwork Details Column */}
          <Col md={4} lg={4}>
          {sellerList.map((item:any) => (
            <div className="artwork-info">
              <h3>{item.Title}</h3>
              <p>{item.Description}</p>
              <p>
                <strong>Category:</strong> <a href="#">{item.category_name}</a> <br />
                <strong>Mediums: </strong> <a href="#">{item.mediums_name}</a> <br />
                <strong>Size: </strong> 36 X 60
              </p>
              <p>
                <a className="label label-success" href="#">
                  {item.Label}
                </a>{' '}
                {/* <a className="label label-success" href="#">
                  Folk
                </a>{' '}
                <a className="label label-success" href="#">
                  Canvas
                </a>{' '}
                <a className="label label-success" href="#">
                  Brown
                </a> */}
              </p>
              <p>
              <span>Original Sale</span>
  {item.Is_available_for_original_sale === 'yes' ? (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
    </span>
  ) : (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
    </span>
  )}
  
                <span style={{ marginLeft: '15px' }}>Print Sale</span>
                {/* <span style={{ marginLeft: '5px' }}>
                  <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                </span> */}
  
  
  {item.Is_available_for_print_sale === 'yes' ? (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
    </span>
  ) : (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
    </span>
  )}
  
                <span style={{ marginLeft: '15px' }}>Desktop Art Sale</span>
                {/* <span style={{ marginLeft: '5px' }}>
                  <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                </span> */}
  {item.Is_vailable_for_desktop_art === 'yes' ? (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
    </span>
  ) : (
    <span style={{ marginLeft: '5px' }}>
      <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
    </span>
  )}
  
              </p>
  
              <button 
    style={{ marginInlineEnd: '10px' }}
   

    className="btn btn-success" 
    onClick={(e) => {
        e.preventDefault();
        handleViewDetailsClick(item.id);
        
    }}
>
    <i className="fa fa-pencil"></i> Edit this Artwork
</button>

  



  
           
              {/* <Button variant="primary" className="ladda-button" onClick={() => handleDeleteClick(item)=> setShowDisableModal(true)}>
                Disable this Artwork
              </Button> */}
  
  <Button
  variant="primary"
  className="ladda-button"
  style={{ marginInlineEnd: '10px' }}
  onClick={() => handleDisableClick(item.id)}
>
  Disable this Artwork
</Button>







  
  
              {/* Delete Artwork Button */}
              <Button variant="danger" onClick={() => handleDeleteClick(item.id)}>
                Delete this Artwork
              </Button>
            </div>
              ))}
          </Col>
          <div className="col-md-3">
  <div className="box-body box-profile">
    <img
      className="profile-user-img img-responsive img-circle"
      src="https://d3qxj82trr8cp9.cloudfront.net/profilepic/03_02_22_01_Dinkar_Dinkar%20Jadhav.jpg"
      alt="User profile picture"
    />
    <h3 className="profile-username text-center">Dinkar Jadhav</h3>

    <ul className="list-group list-group-unbordered">
      <li className="list-group-item" style={{ textAlign: 'center' }}>
        <a href="mailto:dinkar.jadhav2@gmail.com">
          <i className="fa fa-envelope-o"></i> dinkar.jadhav2@gmail.com
        </a>
      </li>
      <li className="list-group-item" style={{ textAlign: 'center' }}>
        <a href="https://admin.artflute.com/admin/artist/240">
          <i className="fa fa-eye"></i> View Profile
        </a>
      </li>
    </ul>
  </div>
  {/* /.box-body */}
</div>

        </Row>
  







































        {/* Tabs for Gallery and Other Sections Positioned Below */}
        <div className="artwork-tabs mt-4">
          <Tabs
            id="artwork-details-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k || 'gallery')}
            className="mb-3"
          >
            <Tab eventKey="gallery" title="Gallery">
            
              <Button
                className="btn btn-primary ladda-button mb-3"
                onClick={handleAddImageClick}
              >
                Add Artwork Image
              </Button>
  
             
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Image</th>
                    <th>Dimensions</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerList.map((item:any) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <img
                          src={item.Artwork_image}
                          alt="Thumbnail"
                          style={{ width: '50px' }}
                        />
                      </td>
                      <td>{item.dimensions}</td>
                      <td>
                        <Button variant="danger" onClick={() => alert('Delete Action')}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
  
            <Tab eventKey="highResolution" title="High Resolution Images">
              <div className="tab-content">
                <Button className="btn btn-primary ladda-button"   onClick={handlehighresollutionsClick}>
                  Add High-Res Image
                </Button>
  
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Image</th>
                      <th>Dimensions</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                    <tr>
                      <td>22639</td>
                      <td>
                        <img
                          style={{ height: '240px', width: '340px!important' }}
                          src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/31_08_24_01_13_00_IMG20230825181002.jpg"
                          alt="High Resolution Artwork"
                        />
                      </td>
                      <td>
                        <p><strong>Is high resolution?</strong> No</p>
                        <p><strong>Width: </strong></p>
                        <p><strong>Height: </strong></p>
                        <p><strong>Pixel: </strong></p>
                      </td>
                      <td className="item-actions">
                        <a href="https://admin.artflute.com/admin/artworkimage/22639/edit">Edit</a>
                        <a href="https://admin.artflute.com/admin/artworkimage/22639" data-button-type="delete">Delete</a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
  
            <Tab eventKey="printavailability" title="Print Availability">
              <div className="tab-content">
                <Button className="btn btn-primary ladda-button mb-3" onClick={handleaddprintsClick}>
                  Add Print Availability
                </Button>
  
                {/* Table for print availability */}
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Criteria</th>
                      <th>Price - INR</th>
                      <th>Price - USD</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Table rows will go here */}
                  </tbody>
                </Table>
              </div>
            </Tab>
  
            <Tab eventKey="commentsReviews" title="Comments/Reviews">
              <div className="tab-content">
                {/* Table for comments and reviews */}
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Comment/Review</th>
                      <th>User</th>
                      <th className="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Table rows will go here */}
                  </tbody>
                </Table>
              </div>
            </Tab>
  
            <Tab eventKey="activitylog" title="Activity log">
              <div className="tab-content">
                <p>
                  <a className="show-more" href="/admin/audit/feed/96232f7b-0db9-419e-808b-a5787fc73303">
                    show more
                  </a>
                </p>
                <p>
                  <a href="/admin/userCustomer/302" target="_blank">User Details</a>
                </p>
              </div>
            </Tab>
          </Tabs>
        </div>
  
        {/* Disable Artwork Modal */}
        <Modal show={showDisableModal} onHide={() => setShowDisableModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Disable Artwork</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to disable this artwork?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDisableModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDisableConfirm}>
              Disable
            </Button>
          </Modal.Footer>
        </Modal>
  
        <Modal show={showAddImageModal} onHide={handleCloseAddImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Artwork Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <Form
                //   method="POST"
                //   action="https://admin.artflute.com/admin/galleryimage/add"
                //   encType="multipart/form-data"
                //   data-parsley-validate
                //   noValidate
                >
                  <Form.Group>
                    <Form.Label>Image <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control type="file" name="image_upload" required />
                  </Form.Group>

                  <input type="hidden" id="isHighRes" name="is_high_res" value="0" />

                  <Form.Group>
                    <Form.Label>Dimension Width</Form.Label>
                    <Form.Control type="text" name="dimension_width" />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Dimension Height</Form.Label>
                    <Form.Control type="text" name="dimension_height" />
                  </Form.Group>

                  <input type="hidden" name="artwork_id" value="14147" />

                
                  <Button variant="success" type="submit" style={{ marginRight: '10px', marginTop:'20px' }}>
                    Save
                  </Button>
                  <Button variant="secondary" style={{marginTop:'20px' } } onClick={handleCloseAddImageModal}>
                    Cancel
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>







      <Modal show={showHighresolutionModal} onHide={handleCloseAddhighresolutionsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add High Rise Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <Form
                  method="POST"
                  action="https://admin.artflute.com/admin/galleryimage/add"
                  encType="multipart/form-data"
                  data-parsley-validate
                  noValidate
                >
                  <Form.Group>
                    <Form.Label>Image <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control type="file" name="image_upload" required />
                  </Form.Group>

                  <input type="hidden" id="isHighRes" name="is_high_res" value="0" />

                  <Form.Group>
                    <Form.Label>Dimension Width</Form.Label>
                    <Form.Control type="text" name="dimension_width" />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Dimension Height</Form.Label>
                    <Form.Control type="text" name="dimension_height" />
                  </Form.Group>

                  <input type="hidden" name="artwork_id" value="14147" />

                  <Button variant="success" type="submit" style={{ marginRight: '10px', marginTop:'20px' }}>
                    Save
                  </Button>
                  <Button variant="secondary" style={{marginTop:'20px' } } onClick={handleCloseAddhighresolutionsModal}>
                    Cancel
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      




      <Modal show={showprintavailabltyModal} onHide={handleCloseAddprintModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Print Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="printForm" onSubmit={handleCloseAddprintModal}>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Select name="size_title" id="printTitle" required>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Size Value</Form.Label>
              <Form.Control type="text" name="size_value" id="printValue" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control type="text" name="quantity" defaultValue="10" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price - INR</Form.Label>
              <Form.Control type="text" name="price" id="printPrice" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price - USD</Form.Label>
              <Form.Control type="text" name="price_dollar" id="printPriceDollar" required />
            </Form.Group>

            <input type="hidden" name="artwork_print_subscription_criteria_id" value="17" />
            <input type="hidden" name="artwork_id" value="14144" />
            <input type="hidden" name="city_id" value="0" />

            <Button variant="success" type="submit" style={{ marginRight: '10px' }}>
              Save
            </Button>
            <Button variant="primary" onClick={handleCloseAddprintModal}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      
        {/* Delete Artwork Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Artwork</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this artwork?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      
    );
  };
  
  export default ArtworkDetails;