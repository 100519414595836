import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

interface CreateProductStep1Props {
    formik: any;
}

const EditseoStep3: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template"
    
    const [loader, setLoader] = useState<boolean>(false);

    const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = formik;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files.length > 0) {
            try {
                let uploadedPaths: string[] = [];
                const files = event.target.files;

                for (let i = 0; i < files.length; i++) {
                    let data: any = new FormData();
                    data.append('fileType', 'artist_thumb_desktop');
                    data.append('filePath', files[i]);

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: data
                    };

                    const response = await axios.request(config);
                    const filePath = response.data.mediaPaths[0].filePath;
                    uploadedPaths.push(filePath);
                }

            
                const seoImagePaths = uploadedPaths.join(',');
                setFieldValue(fieldName, seoImagePaths);
            } catch (error) {
                console.error("Error uploading images: ", error);
            }
        }
    };
    const location = useLocation();
    const { data: id } = location.state || {};
    const fetchartworksList = async () => {
        setLoader(true);
        try {
          const tokenString = localStorage.getItem('authUser');
          let token = '';
      
          if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
          }
      
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artwork_by_id`,
            {
              params: { id },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          const artworkData = response.data;
          if (Array.isArray(artworkData) && artworkData.length > 0) {
            const firstItem = artworkData[0]; 
      console.log(firstItem, "firstItemfirstItem")
              setFieldValue("seo_title", firstItem.seo_title || "");
              setFieldValue("seo_description", firstItem.seo_description || "");
              setFieldValue("seo_image", firstItem.seo_image || "");


          } else {
            console.warn('No artwork data available');
          }
      
        } catch (error) {
          console.error('Error fetching artwork list:', error);
        } finally {
          setLoader(false);
        }
      };
      
      useEffect(() => {
        fetchartworksList();
      }, []);


    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Edit Seo</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_title">
                                                    <Form.Label>Seo Title </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="seo_title"
                                                        placeholder="Enter Seo Title"
                                                        value={values.seo_title}
                                                  
                                                        onChange={(e) => setFieldValue('seo_title', e.target.value)} 
                                            
                                                        isInvalid={!!errors.seo_title && touched.seo_title}
        
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_keyword">
                                                    <Form.Label>Seo Keyword </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="seo_keyword"
                                                        placeholder="Enter Seo Keyword"
                                                        value={values.seo_keyword}
                                                
                                                        onChange={(e) => setFieldValue('seo_keyword', e.target.value)} 
                                                        isInvalid={!!errors.seo_keyword && touched.seo_keyword}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_keyword}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_description">
                                                    <Form.Label>Seo Description </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="seo_description"
                                                        placeholder="Seo description"
                                                        value={values.seo_description}
                                                     
                                                        onChange={(e) => setFieldValue('seo_description', e.target.value)} 
                                                        isInvalid={!!errors.seo_description && touched.seo_description}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_description}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_image">
                                                    <Form.Label>Seo Image (Upload multiple images)</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="seo_image"
                                                        multiple
                                                        onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, 'seo_image')}
                                                        isInvalid={!!errors.seo_image && touched.seo_image}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_image}
                                                    </Form.Control.Feedback>
                                                    {values.seo_image && values.seo_image.split(',').map((image: string, index: number) => (
                                                        <img
                                                            key={index}
                                                            src={image}
                                                            alt={`SEO Image ${index + 1}`}
                                                            style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                                                        />
                                                    ))}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default EditseoStep3;
