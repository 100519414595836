import axios from 'axios';
import React from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import deleteicon from 'assets/images/delete icon.png';

interface ArtistEssayProps {
  formik: any;
}

const ArtistEssay: React.FC<ArtistEssayProps> = ({ formik }) => {
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    if (event.target.files && event.target.files[0]) {
      try {
        const data = new FormData();
        data.append('fileType', 'artist_thumb_desktop');
        data.append('filePath', event.target.files[0]);

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        };

        const response = await axios.request(config);
        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
      } catch (error) {
        console.error('Error uploading image', error);
      }
    }
  };

  const handleRemoveItem = (arrayName: string, index: number) => {
    const updatedArray = [...values[arrayName]];
    updatedArray.splice(index, 1);
    setFieldValue(arrayName, updatedArray);
  };

  const handleAddItem = (arrayName: string) => {
    console.log(arrayName, "arrayNamearrayNamearrayNamearrayNamearrayNamearrayNamearrayName" )
    const updatedArray = [...(values[arrayName] || []), { name: '' }];
    setFieldValue(arrayName, updatedArray);
  };

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCollection = [...values.collection];
    updatedCollection[index].description = e.target.value; 
    setFieldValue(`essay_description${index + 1}`, e.target.value);
    setFieldValue('collection', updatedCollection); 
  };
  
  const handleTitleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCollection = [...values.collection];
    updatedCollection[index].title = e.target.value;
    setFieldValue(`essay_title${index + 1}`, e.target.value); 
    setFieldValue('collection', updatedCollection); 
  };
  

  
  return (
    <Row>
      <Col lg={4}>
        <h5 className="fs-16">Artist Essays</h5>
        <p className="text-muted mb-lg-0">
          Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.
        </p>
      </Col>
      <Col lg={8}>
        <Card>
          <Card.Body>
            <Row className="g-3">
           

            <Col lg={12}>
  {values.collection.map((collection: { title: string; description: string }, index: number) => (
    <div key={index} className="d-flex mb-2">
      <div style={{ paddingBottom: '10px', width: '100%' }}>
        <Form.Label>Essay Title {index + 1}</Form.Label>
        <Form.Control
          type="text"
          value={collection.title}
          placeholder="Enter title"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTitleChange(index, e)
          }
          isInvalid={!!errors.collection?.[index]?.title && touched.collection?.[index]?.title}
        />
        <Form.Control.Feedback type="invalid" className="text-danger">
          {errors.collection?.[index]?.title || ''}
        </Form.Control.Feedback>
      </div>
      <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
        <Button variant="link" onClick={() => handleRemoveItem('collection', index)}>
          <img src={deleteicon} alt="delete" height={18} width={18} />
        </Button>
      </div>
    </div>
  ))}

</Col>

<Col lg={12}>
  {values.collection.map((collection: { title: string; description: string }, index: number) => (
    <div key={index} className="d-flex mb-2">
      <div style={{ paddingBottom: '10px', width: '100%' }}>
        <Form.Label>Essay Description {index + 1}</Form.Label>
        <Form.Control
          type="text"
          value={collection.description}
          placeholder="Enter description"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(index, e)
          }
          isInvalid={!!errors.collection?.[index]?.description && touched.collection?.[index]?.description}
        />
        <Form.Control.Feedback type="invalid" className="text-danger">
          {errors.collection?.[index]?.description || ''}
        </Form.Control.Feedback>
      </div>
      <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
        <Button variant="link" onClick={() => handleRemoveItem('collection', index)}>
          <img src={deleteicon} alt="delete" height={18} width={18} />
        </Button>
      </div>
    </div>
  ))}

</Col>
          
              {(values.collection || [{ name: '' }]).map((item: any, index: number) => (
                <Row key={index}>
                  <Col lg={12}>
                    <Form.Group className='mt-3' controlId={`essay_image${index + 1}`}>
                      <Form.Label>Essay Image {index + 1}</Form.Label>
                      <Form.Control
                        type="file"
                        name={`essay_image${index + 1}`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, `essay_image${index + 1}`)}
                        isInvalid={!!errors[`supporting_image_${index + 1}`] && touched[`essay_image${index + 1}`]}
                      />
                      <Form.Control.Feedback type="invalid" className='text-danger'>
                        {errors[`supporting_image_${index + 1}`]}
                      </Form.Control.Feedback>
                      {index !== 0 && (
                        <Button variant="link" onClick={() => handleRemoveItem('collection', index)}>
                          <img src={deleteicon} alt="delete" height={18} width={18} />
                        </Button>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              ))}

              <Button variant="outline-primary" onClick={() => handleAddItem('collection')}>
                + Add
              </Button>

             
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ArtistEssay;