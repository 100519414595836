import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

interface CreateProductStep1Props {
    formik: any;
}

const EditsupportingImageStep4: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template"

    const { errors, touched, setFieldValue, handleSubmit } = formik;
    const [loader, setLoader] = useState<boolean>(false);

    const multipleimages = useFormik({
        initialValues: {
          supporting_image_1: '',
          supporting_image_2: '',
          supporting_image_3: '',
          supporting_image_4: '',
          supporting_image_5: '',
          supporting_image_6: '',
          supporting_image_7: '',
          supporting_image_8: '',
          supporting_image_9: '',
        
          supporting_image_10: '',

          supporting_image_11: ''


     
        },
      
        onSubmit: (values: any) => {
          // Handle form submission
          console.log('Form Submitted:', values);
        },
      });
    
      const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files.length > 0) {
          try {
            let uploadedPaths: string[] = [];
            const files = event.target.files;
    
            for (let i = 0; i < files.length; i++) {
              let data: FormData = new FormData();
              data.append('fileType', 'artist_thumb_desktop');
              data.append('filePath', files[i]);
    
              let config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: data,
              };
    
              const response = await axios.request(config);
              const filePath = response.data.mediaPaths[0].filePath;
              uploadedPaths.push(filePath);
            }
    
            const seoImagePaths = uploadedPaths.join(',');
            formik.setFieldValue(fieldName, seoImagePaths);
          } catch (error) {
            console.error("Error uploading images: ", error);
          }
        }
      };
    const location = useLocation();
    const { data: id } = location.state || {};

    const fetchartworksList = async () => {
        setLoader(true);
        try {
          const tokenString = localStorage.getItem('authUser');
          let token = '';
      
          if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
          }
      
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artwork_by_id`,
            {
              params: { id },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          const artworkData = response.data;
          if (Array.isArray(artworkData) && artworkData.length > 0) {
            const firstItem = artworkData[0]; 
      console.log(firstItem, "firstItemfirstItem")
multipleimages.setFieldValue("supporting_image_1", firstItem.supporting_image_1 || "");
multipleimages.setFieldValue("supporting_image_2", firstItem.supporting_image_2 || "");
multipleimages.setFieldValue("supporting_image_3", firstItem.supporting_image_3 || "");
multipleimages.setFieldValue("supporting_image_4", firstItem.supporting_image_4 || "");
multipleimages.setFieldValue("supporting_image_5", firstItem.supporting_image_5 || "");
multipleimages.setFieldValue("supporting_image_6", firstItem.supporting_image_6 || "");
multipleimages.setFieldValue("supporting_image_7", firstItem.supporting_image_7 || "");
multipleimages.setFieldValue("supporting_image_8", firstItem.supporting_image_8 || "");
multipleimages.setFieldValue("supporting_image_9", firstItem.supporting_image_9 || "");
multipleimages.setFieldValue("supporting_image_11", firstItem.supporting_image_11 || "");
          } else {
            console.warn('No artwork data available');
          }
      
        } catch (error) {
          console.error('Error fetching artwork list:', error);
        } finally {
          setLoader(false);
        }
      };
      
      useEffect(() => {
        fetchartworksList();
      }, []);


    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Edit Supporting</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                        <Col lg={12}>
                        <Form.Group className='mt-3' controlId="supporting_image_1">
                          <Form.Label>Supporting Image 1 </Form.Label>
                          <Form.Control
                            type="file"
                            name="supporting_image_1"
                            multiple
                            onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, 'supporting_image_1')}
                            isInvalid={!!formik.errors.supporting_image_1 && formik.touched.supporting_image_1}
                          />
                          <Form.Control.Feedback type="invalid" className='text-danger'>
                            {formik.errors.supporting_image_1}
                          </Form.Control.Feedback>
                          {multipleimages.values.supporting_image_1 && multipleimages.values.supporting_image_1.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                        </Form.Group>
                      </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_2">
                                                    <Form.Label>Supporting Image 2</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_2"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_2")}
                                                        isInvalid={!!errors.supporting_image_2 && touched.supporting_image_2}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_2}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_2 && multipleimages.values.supporting_image_2.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_3">
                                                    <Form.Label>Supporting Image 3</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_3"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_3")}
                                                        isInvalid={!!errors.supporting_image_3 && touched.supporting_image_3}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_3}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_3 && multipleimages.values.supporting_image_3.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_4">
                                                    <Form.Label>Supporting Image 4</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_4"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_4")}
                                                        isInvalid={!!errors.supporting_image_4 && touched.supporting_image_4}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_4}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_4 && multipleimages.values.supporting_image_4.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_5">
                                                    <Form.Label>Supporting Image 5</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_5"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_5")}
                                                        isInvalid={!!errors.supporting_image_5 && touched.supporting_image_5}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_5}
                                                    </Form.Control.Feedback>

                                                    {multipleimages.values.supporting_image_5 && multipleimages.values.supporting_image_5.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}

                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_6">
                                                    <Form.Label>Supporting Image 6</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_6"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_6")}
                                                        isInvalid={!!errors.supporting_image_6 && touched.supporting_image_6}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_6}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_6 && multipleimages.values.supporting_image_6.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_7">
                                                    <Form.Label>Supporting Image 7</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_7"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_7")}
                                                        isInvalid={!!errors.supporting_image_7 && touched.supporting_image_7}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_7}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_7 && multipleimages.values.supporting_image_7.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_8">
                                                    <Form.Label>Supporting Image 8</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_8"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_8")}
                                                        isInvalid={!!errors.supporting_image_8 && touched.supporting_image_8}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_8}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_8 && multipleimages.values.supporting_image_8.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_9">
                                                    <Form.Label>Supporting Image 9</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_9"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_9")}
                                                        isInvalid={!!errors.supporting_image_9 && touched.supporting_image_9}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_9}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_9 && multipleimages.values.supporting_image_9.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_10">
                                                    <Form.Label>Supporting Image 10</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_10"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_10")}
                                                        isInvalid={!!errors.supporting_image_10 && touched.supporting_image_10}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_10}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_10 && multipleimages.values.supporting_image_10.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_11">
                                                    <Form.Label>Supporting Image 11</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_11"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_11")}
                                                        isInvalid={!!errors.supporting_image_11 && touched.supporting_image_11}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_11}
                                                    </Form.Control.Feedback>
                                                    {multipleimages.values.supporting_image_11 && multipleimages.values.supporting_image_11.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default EditsupportingImageStep4;