import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import Select, { MultiValue } from 'react-select';
import { useFormik } from 'formik';
import * as yup from "yup";
import { Link, useLocation ,useNavigate} from 'react-router-dom';
interface CreateProductStep1Props {
    formik: any;
    artwork_Id:any
}


const Editcollections1: React.FC<CreateProductStep1Props> = ({ formik,artwork_Id }) => {
    console.log(artwork_Id, "artwork_Idartwork_Id")


     console.log(formik.values.Selection, "formik.values.Selectionformik.values.Selectionformik.values.Selectionformik.values.Selection")

    const location = useLocation();
    const { data: collectionsid } = location.state || {};
    const id=collectionsid
    console.log(collectionsid, "allpartnersallpartnersallpartnersallpartnersallpartners")
    document.title = "Create Product | Artflute + Admin React Template";
    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;
    const [getArtist, setGetArtist] = useState<{ value: string, label: string }[]>([]);
    const [catFilter, setCatFilter] = useState<any[]>([]);

 
    const [sizelist, setsizeList] = useState<any[]>([]);
    const [pricelist, setpriceList] = useState<any[]>([]);
    
    const [oriantationslist, setoriantationsList] = useState<any[]>([]);
    const [stylelist, setstyleList] = useState<any[]>([]);

    const [subjectslist, setsubjectsList] = useState<any[]>([]);
    const [tagslist, settagsList] = useState<any[]>([]);
    

    const [artworklist, setartworkList] = useState<any[]>([]);
    const [artistlist, setartistList] = useState<any[]>([]);
    const [surfacelist, setsurfaceList] = useState<any[]>([]);
    const [medianslist, setmediansList] = useState<any[]>([]);
    const [selectionslist, setselectionsList] = useState<any[]>([]);


    const [categorieslist, setcategoriesList] = useState<any[]>([]);
   
    const [loader, setLoader] = useState<boolean>(false);
  
    const convertToSlug = (text: string): string => {
        return text
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')  
          .replace(/^-+|-+$/g, '');    
      };
      const fetchartstyleList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artstyle_dropdown`,
             
            );

            setstyleList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    

    const fetchartsubjectList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artsubject_dropdown`,
             
            );

            setsubjectsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartselectionsList = async () => {
        setLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_selections_dropdown`,
             
            );
            setselectionsList(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

















    const fetchartmediansList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artmedians_dropdown`,
             
            );

            setmediansList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

   

    const fetchartcategoriesList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artcategories_dropdown`,
             
            );

            setcategoriesList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartsurfaceList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artsurface_dropdown`,
             
            );

            setsurfaceList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };



    const fetchartsizesList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artsize_dropdown`,
             
            );

            setsizeList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartpricesList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artprices_dropdown`,
             
            );

            setpriceList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };


    const fetchartoriantationsList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_art_oriantations_dropdown`,
             
            );

            setoriantationsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetcharttagsList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_arttags_dropdown`,
             
            );

            settagsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
    

    const fetchartartistList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artartist_dropdown`,
             
            );

            setartistList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    const fetchartartworklistList = async () => {
        setLoader(true);
        try {
          

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artwork_dropdown`,
             
            );

            setartworkList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };
   

    useEffect(() => {
        fetchartcategoriesList();
        fetchartstyleList();  
        fetchartsurfaceList();
        fetchartartworklistList()
        fetchartsubjectList(); 
        fetchartmediansList(); 
      
        fetchartsizesList();
        fetchartpricesList();
        fetchartoriantationsList();
        fetcharttagsList();
        fetchartartistList();
        fetchartselectionsList();

    }, []);



    
   

    

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-box-seam"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Collections</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>

                                    <Form.Group className='mt-3' controlId="Title">
  <Form.Label>Name  <span style={{ color: 'red' }}>*</span></Form.Label>
  <Form.Control
    type="text"
    name="Name"
    placeholder="Enter Name"
    value={formik.values.Name}
    onChange={(e) => {
      const selectedName = e.target.value;
      formik.setFieldValue('Name', selectedName);

      formik.setFieldValue('Slug', convertToSlug(selectedName));
    }}
    isInvalid={!!formik.errors.Name && formik.touched.Name}
  />
  <Form.Control.Feedback type="invalid" className='text-danger'>
    {formik.errors.Name}
  </Form.Control.Feedback>
</Form.Group>

<Form.Group style={{ marginTop: "10px" }} controlId="Slug">
  <Form.Label>Slug  <span style={{ color: 'red' }}>*</span></Form.Label>
  <Form.Control
    type="text"
    name="Slug"
    placeholder="Enter Slug"
    value={formik.values.Slug || ""}
    onChange={formik.handleChange}
    isInvalid={!!formik.errors.Slug && formik.touched.Slug}
  />
  <Form.Control.Feedback type="invalid" className='text-danger'>
    {formik.errors.Slug}
  </Form.Control.Feedback>
</Form.Group>



                                        <Row>
                                       

                                        <Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="Medium_id">Medium</Form.Label>
        <Form.Control
            as="select"
            className="form-select custom-select"
            id="Medium_id" 
            onBlur={formik.handleBlur}
            value={formik.values.Medium_id}
            isInvalid={formik.touched.Medium_id && !!formik.errors.Medium_id} 
            onChange={(e) => {
                const selectedValue = Number(e.target.value);
                const selectedArtworkIds = medianslist
                    .filter(item => item.id === selectedValue)
                    .flatMap(item => item.artworkId || []);

                console.log(JSON.stringify(selectedArtworkIds, null, 2), "Selected Artwork IDs");
                console.log(selectedValue, "Selected Medium ID");

                formik.setFieldValue("Medium_id", selectedValue); 
                formik.setFieldValue("Artwork_id", selectedArtworkIds);
            }}
        >
            <option value="">Select mediums</option>
            {medianslist?.length > 0 ? (
                medianslist.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.Label.trim()}
                    </option>
                ))
            ) : (
                <option disabled>No mediums available</option>
            )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
            {formik.errors.Medium_id} 
        </Form.Control.Feedback>
    </div>
</Col>





<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="Surface_id">Surface</Form.Label>
        <Form.Control
            as="select"
            className="form-select custom-select"
            id="Surface_id"
            onBlur={formik.handleBlur}
            value={formik.values.Surface_id}
            isInvalid={formik.touched.Surface_id && !!formik.errors.Surface_id}
            onChange={(e) => {
                const selectedValue = Number(e.target.value); 
                const selectedArtworkIds = surfacelist
                    .filter(item => item.id === selectedValue) 
                    .flatMap(item => item.artworkId || []); 

                console.log(JSON.stringify(selectedArtworkIds, null, 2), "Selected Artwork IDs");
                console.log(selectedValue, "Selected Category ID");

                formik.setFieldValue("Surface_id", selectedValue);
                formik.setFieldValue("Artwork_id", selectedArtworkIds);
            }}
        >
            <option value="">Select surfaces</option>
            {surfacelist?.length > 0 ? (
                surfacelist.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.Label.trim()}
                    </option>
                ))
            ) : (
                <option disabled>No surfaces available</option>
            )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
            {formik.errors.Surface_id}
        </Form.Control.Feedback>
    </div>
</Col>

<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="Category_id">Category</Form.Label>
        <Form.Control
            as="select"
            className="form-select"
            id="Category_id"
            style={{ height: '38px', width: '100%' }}
            onBlur={formik.handleBlur}
            value={formik.values.Category_id}
            isInvalid={formik.touched.Category_id && !!formik.errors.Category_id}
            onChange={(e) => {
                const selectedValue = Number(e.target.value); 

               
                const selectedArtworkIds = categorieslist
                    .filter(item => item.id === selectedValue) 
                    .flatMap(item => item.artworkId || []); 

                console.log(JSON.stringify(selectedArtworkIds, null, 2), "Selected Artwork IDs");
                console.log(selectedValue, "Selected Category ID");

               
                formik.setFieldValue("Category_id", selectedValue);
                formik.setFieldValue("Artwork_id", selectedArtworkIds);
            }}
        >
            <option value="">Select categories</option>
            {categorieslist?.map(item => (
                <option key={item.id} value={item.id}>
                    {item.Category_name.trim()} 
                </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
            {formik.errors.Category_id}
        </Form.Control.Feedback>
    </div>
</Col>


<Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Artists</label>
    
    <Select
      value={
    

        typeof formik.values.Artist_id === 'string' && formik.values.Artist_id.trim() !== ''
        ? formik.values.Artist_id.split(',').map((id: string) => ({
            value: id,
            label: artistlist.find((option: any) => option.id == id)?.Label,
          }))
        : [] 
      }
      options={
        artistlist?.map((item: any) => ({
          value: item.id,
          label: item.Label,
        })) || []
      }
      isMulti
      isClearable
      name="Artist_id"
      onChange={(selectedOptions) => {
  
        if (!selectedOptions || selectedOptions.length === 0) {
          formik.setFieldValue("Artist_id", []);
          formik.setFieldValue("Artwork_id", []);
          return;
        }

      
        const selectedValues = selectedOptions.map((option: any) => option.value);

     
        const selectedArtworkIds = selectedOptions.flatMap((option: any) => {
          const foundItem = artistlist.find((item: any) => item.id === option.value);
          return foundItem ? foundItem.artworkId : []; 
        });

  
        console.log("Selected Artist IDs:", JSON.stringify(selectedValues, null, 2));
        console.log("Selected Artwork IDs:", JSON.stringify(selectedArtworkIds, null, 2));

        formik.setFieldValue("Artist_id", selectedValues.join(',')); 
        formik.setFieldValue("Artwork_id", selectedArtworkIds); 
      }}
    />
  </div>
</Col>



   



<Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Art Style</label>
    <Select
      value={
      

        typeof formik.values.Art_style_id === 'string' && formik.values.Art_style_id.trim() !== ''
        ? formik.values.Art_style_id.split(',').map((id: string) => ({
            value: id,
            label: stylelist.find((option: any) => option.id == id)?.Label,
          }))
        : [] 
      }
      options={
        stylelist?.map((item: any) => ({
          value: item.id,
          label: item.Label,
        })) || []
      }
      isMulti
      isClearable
      name="Art_style_id"
      onChange={(selectedOptions) => {
 
        if (!selectedOptions || selectedOptions.length === 0) {
          setFieldValue("Art_style_id", []);
          setFieldValue("Artwork_id", []); 
          return;
        }

  
        const selectedValues = selectedOptions.map((option: any) => option.value);

       
        const selectedArtworkIds = selectedOptions.flatMap((option: any) => {
          const foundItem = stylelist.find((item: any) => item.id === option.value);
          return foundItem ? foundItem.artworkId : []; 
        });

 
        console.log("Selected Art Style IDs:", JSON.stringify(selectedValues, null, 2));
        console.log("Selected Artwork IDs:", JSON.stringify(selectedArtworkIds, null, 2));

        setFieldValue("Art_style_id", selectedValues.join(',')); 
        setFieldValue("Artwork_id", selectedArtworkIds); 
      }}
    />
  </div>
</Col>





<Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Art Subjects</label>
    <Select
      value={
        
     
        typeof formik.values.Art_Subject_id === 'string' && formik.values.Art_Subject_id.trim() !== ''
        ? formik.values.Art_Subject_id.split(',').map((id: string) => ({
            value: id,
            label: subjectslist.find((option: any) => option.id == id)?.Label,
          }))
        : [] 
      }
      options={
        subjectslist?.map((item: any) => ({
          value: item.id,
          label: item.Label,
        })) || []
      }
      isMulti
      isClearable
      name="Art_Subject_id"
      onChange={(selectedOptions) => {
     
        if (!selectedOptions || selectedOptions.length === 0) {
          setFieldValue("Art_Subject_id", []);
          setFieldValue("Artwork_id", []); 
          return;
        }

    
        const selectedValues = selectedOptions.map((option: any) => option.value);

      
        const selectedArtworkIds = selectedOptions.flatMap((option: any) => {
          const foundItem = subjectslist.find((item: any) => item.id === option.value);
          return foundItem ? foundItem.artworkId : []; 
        });

   
        console.log("Selected Artwork IDs", JSON.stringify(selectedArtworkIds, null, 2));
        console.log("Selected Art Subject IDs", JSON.stringify(selectedValues, null, 2));


        setFieldValue("Art_Subject_id", selectedValues.join(',')); 
        setFieldValue("Artwork_id", selectedArtworkIds); 
      }}
    />
  </div>
</Col>



    <Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Price</label>
    <Select
      value={
        
     

        typeof formik.values.Price_id === 'string' && formik.values.Price_id.trim() !== ''
        ? formik.values.Price_id.split(',').map((id: string) => ({
            value: id,
            label: pricelist.find((option: any) => option.id == id)?.price,
          }))
        : [] 
      }
      options={
        pricelist?.map((item: any) => ({
          value: item.id,
          label: item.price,
        })) || []
      }
      isMulti
      isClearable
      name="Price_id"
      onChange={(selectedOptions) => {
 
        if (!selectedOptions || selectedOptions.length === 0) {
          setFieldValue("Price_id", []);
          return;
        }

      
        const selectedValues = selectedOptions.map((option: any) => option.value);


        setFieldValue("Price_id", selectedValues.join(','));
      }}
    />
  </div>
</Col>


    <Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Orientation</label>
    <Select
      value={
        typeof formik.values.Orientation_id === 'string' && formik.values.Orientation_id.trim() !== ''
        ? formik.values.Orientation_id.split(',').map((id: string) => ({
            value: id,
            label: oriantationslist.find((option: any) => option.id == id)?.Label,
          }))
        : [] 
    }
      
      options={
        oriantationslist?.map((item: any) => ({
          value: item.id,
          label: item.Label,
        })) || []
      }
      isMulti
      isClearable
      name="Orientation_id"
      onChange={(selectedOptions) => {
       
        if (!selectedOptions || selectedOptions.length === 0) {
          setFieldValue("Orientation_id", []);
          setFieldValue("Artwork_id", []);
          return;
        }

        const selectedValues = selectedOptions.map((option: any) => option.value);

   
        const selectedArtworkIds = selectedOptions.flatMap((option: any) => {
          const foundItem = oriantationslist.find((item: any) => item.id === option.value);
          return foundItem ? foundItem.artworkId : []; // Return empty array if no match
        });

        console.log("Selected Artwork IDs", JSON.stringify(selectedArtworkIds, null, 2));
        console.log("Selected Orientation IDs", JSON.stringify(selectedValues, null, 2));

        setFieldValue("Orientation_id", selectedValues.join(','));
        setFieldValue("Artwork_id", selectedArtworkIds); 
      }}
    />
  </div>
</Col>
                              

                                          


<Col md={3}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Size</label>
    
    <Select
      value={
        // Ensure formik.values.Size_id is a string and not null/undefined
        typeof formik.values.Size_id === 'string' && formik.values.Size_id.trim() !== ''
          ? formik.values.Size_id.split(',').map((id: string) => ({
              value: id,
              label: sizelist.find((option: any) => option.id == id)?.size,
            }))
          : [] // Show empty if Size_id is null, undefined, or empty
      }
      options={
        sizelist?.map((item: any) => ({
          value: item.id,
          label: item.size,
        })) || []
      }
      isMulti
      isClearable
      name="Size_id"
      onChange={(selectedOptions) => {
       
        if (!selectedOptions || selectedOptions.length === 0) {
          formik.setFieldValue("Size_id", ""); 
          formik.setFieldValue("Artwork_id", []); 
          return;
        }

        
        const selectedValues = selectedOptions.map((option: any) => option.value);

        
        const selectedArtworkIds = selectedOptions.flatMap((option: any) => {
          const foundItem = sizelist.find((item: any) => item.id === option.value);
          return foundItem ? foundItem.artworkId : [];
        });

     
        console.log("Selected Size IDs:", JSON.stringify(selectedValues, null, 2));
        console.log("Selected Artwork IDs:", JSON.stringify(selectedArtworkIds, null, 2));


        formik.setFieldValue("Size_id", selectedValues.join(',')); 
        formik.setFieldValue("Artwork_id", selectedArtworkIds); 
      }}
    />
  </div>
</Col>

                                      
                                    
<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="surface">Selection</Form.Label>
        <Form.Control
            as="select"
            className="form-select"
            id="Selection"
            style={{ height: '38px', width: '100%' }}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.Selection && !!formik.errors.Selection}
     
            value={
                formik.values.Selection === "is_original"
                    ? "only_original"
                    : formik.values.Selection === "is_print"
                    ? "only_prints"
                    : formik.values.Selection === "is_original,is_print"
                    ? "both"
                    : formik.values.Selection
            }
            onChange={(e) => {
                const selectionValue = e.target.value;
                let is_original = "no";
                let is_print = "no";

                if (selectionValue === 'only_original') {
                    is_original = "yes";
                } else if (selectionValue === 'only_prints') {
                    is_print = "yes";
                } else if (selectionValue === 'both') {
                    is_original = "yes";
                    is_print = "yes";
                }

             
                formik.setFieldValue("Selection", selectionValue);
                formik.setFieldValue("is_original", is_original);
                formik.setFieldValue("is_print", is_print);

                if (selectionValue === "only_original") {
                    const foundItems = selectionslist.filter(
                        (item: any) => item.Is_available_for_original_sale === "yes"
                    );
                    const artworkIds = foundItems.map(item => item.id);
                    formik.setFieldValue("Artwork_id", artworkIds);
                } else if (selectionValue === "only_prints") {
                    const foundItems = selectionslist.filter(
                        (item: any) => item.Is_available_for_print_sale === "yes"
                    );
                    const artworkIds = foundItems.map(item => item.id);
                    formik.setFieldValue("Artwork_id", artworkIds);
                } else if (selectionValue === "both") {
                    const foundItems = selectionslist.filter(
                        (item: any) =>
                            item.Is_available_for_print_sale === "yes" ||
                            item.Is_available_for_original_sale === "yes"
                    );
                    const artworkIds = foundItems.map(item => item.id);
                    formik.setFieldValue("Artwork_id", artworkIds);
                }
            }}
        >
            <option value="">Select Selection</option>
            <option value="only_original">Only Original</option>
            <option value="only_prints">Only Prints</option>
            <option value="both">Both Prints and Originals</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {formik.errors.Selection}
        </Form.Control.Feedback>
    </div>
</Col>

<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="surface">Sort</Form.Label>
        <Form.Control
       
            as="select"
            className="form-select"
            id="Sort"
            style={{ height: '38px', width: '100%' }}
            
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.Sort && !!formik.errors.Sort}
            onChange={(e) => {
                const selectionValue = e.target.value;
               
               
                const today = new Date();
                const twoDaysAgo = new Date();
                twoDaysAgo.setDate(today.getDate() - 2);

                let sortedList = [];
               
                if (selectionValue === 'Recently added') {
     
                    sortedList = selectionslist.filter((item: any) => {
                        const createdAt = new Date(item.created_at);
                        return createdAt >= twoDaysAgo && createdAt <= today;
                    });
                    const recent= sortedList.map(item=>item.id)
                    formik.setFieldValue("Artwork_id", recent);
                } else if (selectionValue === 'Previous added') {
               
                    sortedList = selectionslist.filter((item: any) => {
                        const createdAt = new Date(item.created_at);
                        return createdAt < twoDaysAgo;
                    });

                    const previous= sortedList.map(item=>item.id)
                    formik.setFieldValue("Artwork_id", previous);
                }

                console.log(sortedList, "Sorted List");

               
                formik.setFieldValue("SortedSelections", sortedList);
            }}
        >
            <option value="">Select Sort</option>
                                                    <option value="Recently added">Recently added</option>
                                                    <option value="Previous added">Previous added</option>
                                                   
             
       
        </Form.Control>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {formik.errors.Sort}
        </Form.Control.Feedback>
    </div>
</Col>


<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="Surface_id">Tags</Form.Label>
        <Form.Control
            as="select"
            className="form-select custom-select"
            id="id"
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.Medium_id && !!formik.errors.id}
            onChange={(e) => {
                const selectedValue = Number(e.target.value); 
                const selectedArtworkIds = tagslist
                    .filter(item => item.id === selectedValue) 
                    .map(item => item.id); 

                console.log(JSON.stringify(selectedArtworkIds, null, 2), "Selected Artwork IDs");
                console.log(selectedValue, "Selected Category ID");

                formik.setFieldValue("id", selectedValue);
                formik.setFieldValue("Artwork_id", selectedArtworkIds);
            }}
        >
            <option value="">Select tags</option>
            {tagslist?.length > 0 ? (
                tagslist.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.Label}
                    </option>
                ))
            ) : (
                <option disabled>No tags available</option>
            )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
            {formik.errors.id}
        </Form.Control.Feedback>
    </div>
</Col>










                                           
<Col md={12}>
  <div className="mb-3" style={{ marginTop: "10px" }}>
    <label className="form-label">Artworks</label>
    <Select
      value={
        typeof formik.values.Artwork_id === 'string'
          ? formik.values.Artwork_id.split(',').map((id: any) => {
              const parsedId = parseInt(id, 10);

             
              if (isNaN(parsedId)) return null;

              const artwork = artworklist.find((item: any) => item.id === parsedId);

           
              if (!artwork) return null;

              return {
                value: parsedId,
                label: artwork?.Label || "",
              };
            }).filter(Boolean) 
          : Array.isArray(formik.values.Artwork_id)
          ? formik.values.Artwork_id.map((id: any) => {
              const parsedId = parseInt(id, 10);

           
              if (isNaN(parsedId)) return null;

              const artwork = artworklist.find((item: any) => item.id === parsedId);

              if (!artwork) return null;

              return {
                value: parsedId,
                label: artwork?.Label || "",
              };
            }).filter(Boolean) 
          : []
      }
      options={artworklist.map((item: any) => ({
        value: item.id,
        label: item.Label,
      }))}
      isMulti
      isClearable
      onChange={(selectedOptions) => {
        const selectedValues = selectedOptions
          ? selectedOptions.map((option: any) => option.value).join(',')
          : '';

        formik.setFieldValue("Artwork_id", selectedValues);

        console.log("Selected Artwork IDs:", selectedValues);
      }}
    />
  </div>
</Col>




    <Form.Group controlId="description" style={{ marginTop: "10px" }}>
  <Form.Label>Description</Form.Label>
  <Form.Control
    as="textarea"
    name="Description" 
    className="form-control"
    rows={3} 
    value={formik.values.Description || ""}
    onChange={formik.handleChange} 
  />
</Form.Group>

<Form.Group controlId="faq" style={{ marginTop: "10px" }}>  
  <Form.Label>FAQ</Form.Label>
  <Form.Control
    as="textarea"
    name="FAQ"  
    className="form-control"
    rows={3}
    value={formik.values.FAQ || ""}
    onChange={formik.handleChange} 
  />
</Form.Group>

<Form.Group controlId="schemaCode" style={{ marginTop: "10px" }}> 
  <Form.Label>Schema Code</Form.Label>
  <Form.Control
    as="textarea"
    name="Schema_code"  
    className="form-control"
    rows={3} 
    value={formik.values.Schema_code || ""}
    onChange={formik.handleChange} 
  />
</Form.Group>



    <Col md={12}>
                                            <Form.Group className='mt-3 me-3 pe-1' controlId="Thumbnail_image">
                                                <Form.Label>Thumbnail image</Form.Label>
                                                {formik.values.Thumbnail_image && typeof formik.values.Thumbnail_image === 'string' ? (
    <div className="mb-2">
        <img
            src={formik.values.Thumbnail_image||''}
            alt=""
            style={{ width: '100px', height: 'auto' }}
        />
    </div>
) : ''}
        
                                                <Form.Control
                                                    type="file"
                                                    name="Thumbnail_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Thumbnail_image")}
                                                    isInvalid={!!errors.Thumbnail_image && touched.Thumbnail_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Thumbnail_image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
   



<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="surface">Collection For</Form.Label>
        <Form.Control
        
            as="select"
            className="form-select"
            id="Sort"
            style={{ height: '38px', width: '100%' }}
            onBlur={formik.handleBlur}
            value={formik.values.Collection_for || ""}
            isInvalid={formik.touched.Collection_for && !!formik.errors.Collection_for}
            onChange={(e) => {
                formik.setFieldValue("Collection_for", e.target.value);
            }}
        >
          
          <option value="">Select options</option>
                                                    <option value="customer">customer</option>
                                                    <option value="browser">Browser</option>
                                                    
             
       
        </Form.Control>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {formik.errors.Collection_for}
        </Form.Control.Feedback>
    </div>
</Col>
                                          


<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="surface">Status</Form.Label>
        <Form.Control
        
            as="select"
            className="form-select"
            id="Sort"
            style={{ height: '38px', width: '100%' }}
            onBlur={formik.handleBlur}
            value={formik.values.Status || ""}
            isInvalid={formik.touched.Status && !!formik.errors.Status}
            onChange={(e) => {
                formik.setFieldValue("Status", e.target.value);
            }}
        >
          
          <option value="">Select options</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    
             
       
        </Form.Control>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {formik.errors.Status}
        </Form.Control.Feedback>
    </div>
</Col>


                                        



<Col md={3}>
    <div className="mb-3" style={{ marginTop: "10px" }}>
        <Form.Label htmlFor="Show_in_header_navigation">Show in header navigation</Form.Label>
        <Form.Control
        
            as="select"
            className="form-select"
            id="Sort"
            style={{ height: '38px', width: '100%' }}
            onBlur={formik.handleBlur}
            value={formik.values.Show_in_header_navigation || ""}
            isInvalid={formik.touched.Show_in_header_navigation && !!formik.errors.Show_in_header_navigation}
            onChange={(e) => {
                formik.setFieldValue("Show_in_header_navigation", e.target.value);
            }}
        >
          
          <option value="">Select options</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    
             
       
        </Form.Control>
        <Form.Control.Feedback type="invalid" className='text-danger'>
            {formik.errors.Show_in_header_navigation}
        </Form.Control.Feedback>
    </div>
</Col>
                                           
<Form.Group controlId="SeoTitle">
  <Form.Label>SEO Title</Form.Label>
  <Form.Control
    type="text"
    name="Seo_title" 
    placeholder="Enter SEO Title"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Seo_title || ""}
    isInvalid={!!formik.errors.Seo_title && formik.touched.Seo_title}
  />
  <Form.Control.Feedback type="invalid" className='text-danger'>
    {formik.errors.Seo_title}
  </Form.Control.Feedback>
</Form.Group>

<Form.Group style={{ marginTop: "10px" }} controlId="SeoDescription"> 
  <Form.Label>SEO Description</Form.Label>
  <Form.Control
    type="text"
    name="Seo_description" 
    placeholder="Enter SEO Description"
    value={formik.values.Seo_description || ""}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    isInvalid={!!formik.errors.Seo_description && formik.touched.Seo_description}
  />
  <Form.Control.Feedback type="invalid" className='text-danger'>
    {formik.errors.Seo_description}
  </Form.Control.Feedback>
</Form.Group>

</Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default Editcollections1;