import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { debounce } from 'lodash';

interface CreateProductStep1Props {
    formik: any;
}

const EditProductStep1: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Create Product | Artflute + Admin React Template";
    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;
    // const [getArtist, setGetArtist] = useState<{ value: string, label: string }[]>([]);

    const [oriantationslist, setoriantaionsList] = useState<any[]>([]);
   
    const [artworklist, setartworkidList] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [getArtist, setGetArtist] = useState<{ value: string, label: string }[]>([]);
    const [getTitles, setGetTitles] = useState<{ label: string }[]>([]);
    
    const [gettitleexist, settitleexist] =useState<any[]>([]);

    const navigate = useNavigate();
 
     
    const location = useLocation();
    const { data: id } = location.state || {};

    console.log('editproduct ID', id);

    const multipleimages = useFormik({
        initialValues: {
            Artwork_image: '',
            Artwork_webp_image: '',
            Artwork_full_image: '',
            Artwork_full_webp_image: '',
         


     
        },
     
        onSubmit: (values: any) => {
          // Handle form submission
          console.log('Form Submitted:', values);
        },
      });


    const fetchartworksList = async () => {
        setLoader(true);
        try {
          const tokenString = localStorage.getItem('authUser');
          let token = '';
     
          if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
          }
     
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artwork_by_id`,
            {
              params: { id },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
     
          const artworkData = response.data;
          if (Array.isArray(artworkData) && artworkData.length > 0) {
            const firstItem = artworkData[0];
      console.log(firstItem, "firstItemfirstItem")
         
            setFieldValue("Artist_id", firstItem.Artist_id || "");
            setFieldValue("Title", firstItem.Title || "");
            setFieldValue("Slug", firstItem.Slug || "");
            setFieldValue("Label", firstItem.Label || "");
            setFieldValue("Description", firstItem.Description || "");
            setFieldValue("Status", firstItem.Status || "");

            setFieldValue("Sold_out", firstItem.Sold_out || "");
            setFieldValue("Weight", firstItem.Weight || "");
            setFieldValue("Dimension_depth", firstItem.Dimension_depth || "");

            setFieldValue("Originals_price", firstItem.Originals_price || "");

            setFieldValue("Originals_price_dollar", firstItem.Originals_price_dollar || "");
            setFieldValue("Year", firstItem.Title || "");
            setFieldValue("Dimension_width", firstItem.Dimension_width || "");
            setFieldValue("Is_available_for_print_sale", firstItem.Is_available_for_print_sale || "");

            setFieldValue("Month", firstItem.Month || "");
            setFieldValue("Dimension_height", firstItem.Dimension_height || "");
            setFieldValue("Limited_period_discount", firstItem.Limited_period_discount || "");
            setFieldValue("Is_vailable_for_desktop_art", firstItem.Is_vailable_for_desktop_art || "");

            setFieldValue("Creation_place", firstItem.Creation_place || "");
            setFieldValue("Orientation_id", firstItem.Orientation_id || "");

            setFieldValue("Price_on_request", firstItem.Price_on_request || "");
            setFieldValue("Is_available_for_original_sale", firstItem.Is_available_for_original_sale || "");
            setFieldValue("Label_for_date", firstItem.Label_for_date || "");
            multipleimages.setFieldValue("Artwork_image", firstItem.Artwork_image || "");
            multipleimages. setFieldValue("Artwork_webp_image", firstItem.Artwork_webp_image || "");

            multipleimages.setFieldValue("Artwork_full_image", firstItem.Artwork_full_image || "");
            multipleimages.setFieldValue("Artwork_full_webp_image", firstItem.Artwork_full_webp_image || "");

          } else {
            console.warn('No artwork data available');
          }
     
        } catch (error) {
          console.error('Error fetching artwork list:', error);
        } finally {
          setLoader(false);
        }
      };
     
      useEffect(() => {
        fetchartworksList();
      }, []);

    
      useEffect(() => {
        const fetchArtists = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artist_name`);
            
            if (response.data && Array.isArray(response.data)) {
              const artists = response.data
                .filter((artist) => artist.name && artist.name.trim() !== '') 
                .filter((artist, index, self) =>
                  index === self.findIndex(a => a.name.trim().toLowerCase() === artist.name.trim().toLowerCase())
                ) 
                .map((artist) => ({
                  value: artist.id.toString(),
                  label: artist.name.trim(),
                }));
      
              setGetArtist(artists);
            } else {
              console.warn("No artist data available");
            }
      
            if (response.data && Array.isArray(response.data.title)) {
              const titles = response.data.title
                .map((titleObj: { Title: string; }) => titleObj.Title?.toLowerCase().trim())
                .filter((title: any) => !!title); 
      
              setGetTitles(titles);
            } else {
              console.warn("No title data available");
            }
          } catch (error) {
            console.error("Error fetching artist names", error);
          }
        };
      
        fetchArtists();
      }, []);



      const fetchTitles = useCallback(
        debounce(async (newTitle: string) => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_titlename`);
            
            if (response.data && Array.isArray(response.data)) {
              const titles = response.data
                .filter((titleObj: { Title: string }) => titleObj.Title && titleObj.Title.trim() !== '')
                .map((titleObj: { Title: string }) => ({
                  label: titleObj.Title.trim().toLowerCase(),
                }));
              
              setGetTitles(titles);
    
              // Check if title already exists after fetching
              const titleExists = titles.some(item => item.label === newTitle.toLowerCase().trim());
         
              if (titleExists) {
                setFieldValue('TitleError', 'Title already exists.');
              } else {
                setFieldValue('TitleError', '');
              }
            } else {
              console.warn("No title data available");
            }
          } catch (error) {
            console.error("Error fetching title names", error);
          }
        }, 500), // 500ms debounce
        []
      );
      
      const handleTitleChange = (e: React.ChangeEvent<any>) => { 
        const newTitle = e.target.value;
        handleChange(e);  
      console.log(newTitle, "newTitlenewTitlenewTitlenewTitlenewTitlenewTitlenewTitle")
      
      console.log(getTitles, "getTitlesgetTitlesgetTitlesgetTitlesgetTitlesgetTitles")
      console.log(JSON.stringify(getTitles, null, 2), "Response data");
  fetchTitles(newTitle);  
   console.log( fetchTitles(newTitle), "llllllllllllllllllllllllllllll" )
        const slugValue = newTitle
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')  // Remove special characters
          .replace(/\s+/g, '-')      // Replace spaces with hyphens
          .replace(/-+/g, '-');      // Replace multiple hyphens with a single one
      
        setFieldValue('Slug', slugValue); 
      };
    //   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newTitle = e.target.value;
    //     handleChange(e);
    
    //     // Generate slug from title
    //     const slugValue = newTitle
    //         .toLowerCase()
    //         .trim()
    //         .replace(/[^\w\s-]/g, '')  // Remove special characters
    //         .replace(/\s+/g, '-')       // Replace spaces with dashes
    //         .replace(/-+/g, '-');       // Replace multiple dashes with a single dash
    
    //     setFieldValue('Slug', slugValue);
    
    //     // Check if title already exists in titles or artist names
    //     const isTitleDuplicateInTitles = getTitles.includes(newTitle.toLowerCase().trim());
    //     const isTitleDuplicateInArtists = getArtist.some(artist => artist.label.toLowerCase().trim() === newTitle.toLowerCase().trim());
    
    //     if (isTitleDuplicateInTitles || isTitleDuplicateInArtists) {
    //         // Set error message and focus back to Title field
    //         setFieldValue('TitleError', 'Title already exists.');
            
    //         // Prevent moving to the next field by focusing back on the Title field
    //         setTimeout(() => {
    //             document.getElementsByName('Title')[0].focus();
    //         }, 0);
    //     } else {
    //         // Clear error message if title is unique
    //         setFieldValue('TitleError', '');
    //     }
    // };
    

    const fetchartoriantationsList = async () => {
        setLoader(true);
        try {
         

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_art_oriantations_dropdown`,
             
            );

            setoriantaionsList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchartoriantationsList();
    }, []);

console.log(oriantationslist, "oriantationslistoriantationslist")

const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        let uploadedPaths: string[] = [];
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
          let data: FormData = new FormData();
          data.append('fileType', 'artist_thumb_desktop');
          data.append('filePath', files[i]);

          let config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          };

          const response = await axios.request(config);
          const filePath = response.data.mediaPaths[0].filePath;
          uploadedPaths.push(filePath);
        }

        const seoImagePaths = uploadedPaths.join(',');
        formik.setFieldValue(fieldName, seoImagePaths);
      } catch (error) {
        console.error("Error uploading images: ", error);
      }
    }
  };
    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-box-seam"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Artwork Information</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                 
                                 
  {/* <Form.Group className='mt-3' controlId="artist_id">
    <Form.Label>Artist</Form.Label>
    <Form.Select
      name="artist_id"
      value={values.artist_id || ""}
      onChange={(e) => setFieldValue('artist_id', e.target.value)}
      isInvalid={!!errors.artist_id && touched.artist_id}
    >
      <option value="">Select</option>
      {getArtist.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
    <Form.Control.Feedback type="invalid" className='text-danger'>
      {errors.artist_id}
    </Form.Control.Feedback>
  </Form.Group> */}


<Form.Group className='mt-3' controlId="artist_id">
                                            <Form.Label>Artist</Form.Label>
                                            <Form.Select
                                                name="artist_id"
                                                value={values.Artist_id || ""}
                                                onChange={(e) => setFieldValue('Artist_id', e.target.value)}
                                                isInvalid={!!errors.Artist_id && touched.Artist_id}
                                            >
                                                <option value="">Select</option>
                                                {getArtist.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Artist_id}
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                        <Form.Group className="mt-3" controlId="Title">
  <Form.Label>
    Title <span className="text-danger">*</span>
  </Form.Label>
  <Form.Control
    type="text"
    name="Title"
    placeholder="Enter Title"
    value={values.Title}
    onChange={(e) => handleTitleChange(e)}  
    isInvalid={!!errors.Title && touched.Title}
  />
  <Form.Control.Feedback type="invalid" className="text-danger">
    {errors.Title}
  </Form.Control.Feedback>
</Form.Group>
                                        <Form.Group className='mt-3' controlId="Slug">
                                            <Form.Label>Slug </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Slug"
                                                placeholder="Enter Slug"
                                                value={values.Slug}
                                                onChange={(e) => setFieldValue('Slug', e.target.value)}
                                                isInvalid={!!errors.Slug && touched.Slug}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Slug}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Label">
                                            <Form.Label>Label </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Label"
                                                placeholder="Enter Label"
                                                value={values.Label}
                                              onChange={(e) => setFieldValue('Label', e.target.value)}
                                                isInvalid={!!errors.Label && touched.Label}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Label}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Description">
                                            <Form.Label>Description </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="Description"
                                                placeholder="Description"
                                                value={values.Description}
                                                onChange={(e) => setFieldValue('Description', e.target.value)}
                                                isInvalid={!!errors.Description && touched.Description}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-images"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Artwork Gallery</h5>
                                                <p className="text-muted mb-0">Add Artwork gallery images.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Row>
                                        {/* Artwork Image */}
                                        <Col md={6} sm={6} xs={12}>
                                            <Form.Group className='mt-3 ms-3 ps-1' controlId="Artwork_image">
                                                <Form.Label>Artwork image  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="Artwork_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Artwork_image")}
                                                    isInvalid={!!errors.Artwork_image && touched.Artwork_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Artwork_image}
                                                </Form.Control.Feedback>
                                                {multipleimages.values.Artwork_image && multipleimages.values.Artwork_image.split(',').map((image: string, index: number) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Supporting Image 1 ${index + 1}`}
                              style={{ width: '100px', height: 'auto', marginTop: '10px', marginRight: '10px' }}
                            />
                          ))}
                                            </Form.Group>
                                        </Col>
                                    
                                    </Row>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">General Information</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Status">
                                                    <Form.Label>Status </Form.Label>
                                                    <Form.Select
                                                        name="Status"
                                                        value={values.Status || ""}
                                                        onChange={(e) => setFieldValue('Status', e.target.value)}
                                                        isInvalid={!!errors.Status && touched.Status}
                                                    >
                                                        <option value="">Select</option>
                                                   
                                                        <option value="1">Waiting for approval</option>
                                                        <option value="2">Approved</option>
                                                        <option value="3">Sold Out</option>
                                                        <option value="4">Not approved</option>
                                                        <option value="5">Enable</option>
                                                        <option value="6">Available For Commission Work</option>
                                                        <option value="7">Deleted</option>
                                                        <option value="8">Disabled</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Status}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mt-3' controlId="sold_out">
                                                    <Form.Label>Sold out? </Form.Label>
                                                    <Form.Select
                                                        name="sold_out"
                                                        value={values.Sold_out || ""}
                                                        onChange={(e) => setFieldValue('Sold_out', e.target.value)}
                                                        isInvalid={!!errors.Sold_out && touched.Sold_out}
                                                    >
                                                        <option value="">Select</option>
                                                        <option defaultValue="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Sold_out}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mt-3' controlId="sold_out">
                                                    <Form.Label>Is available for original sale?</Form.Label>
                                                    <Form.Select
                                                        name="Is_available_for_original_sale"
                                                        value={values.Is_available_for_original_sale || ""}
                                                        onChange={(e) => setFieldValue('Is_available_for_original_sale', e.target.value)}
                                                        isInvalid={!!errors.Is_available_for_original_sale && touched.Is_available_for_original_sale}
                                                    >
                                                        <option value="">Select</option>
                                                        <option defaultValue="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Is_available_for_original_sale}
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group className='mt-3' controlId="Weight">
                                                    <Form.Label>Weight(in Kg) </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Weight"
                                                        placeholder="Enter weight"
                                                        value={values.Weight}
                                                        // onChange={handleChange}
                                                        onChange={(e) => setFieldValue('Weight', e.target.value)}
                                                        isInvalid={!!errors.Weight && touched.Weight}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Weight}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_depth">
                                                    <Form.Label>Dimension Depth </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_depth"
                                                        placeholder="Enter dimension depth"
                                                        value={values.Dimension_depth}
                                                        onChange={(e) => setFieldValue('Dimension_depth', e.target.value)}
                                                        isInvalid={!!errors.Dimension_depth && touched.Dimension_depth}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_depth}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Originals_price">
                                                    <Form.Label>Originals price </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Originals_price"
                                                        placeholder="Enter Originals price"
                                                        value={values.Originals_price}
                                                 
                                                        onChange={(e) => setFieldValue('Originals_price', e.target.value)}
                                                        isInvalid={!!errors.Originals_price && touched.Originals_price}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Originals_price}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Originals_price_dollar">
                                                    <Form.Label>Originals price dollar </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Originals_price_dollar"
                                                        placeholder="Enter Originals price dollar"
                                                        value={values.Originals_price_dollar}
                                                        onChange={(e) => setFieldValue('Originals_price_dollar', e.target.value)}
                                                        isInvalid={!!errors.Originals_price_dollar && touched.Originals_price_dollar}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Originals_price_dollar}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_width">
                                                    <Form.Label>Dimension Width <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_width"
                                                        placeholder="Enter Dimension Width"
                                                        value={values.Dimension_width}
                                                     
                                                        onChange={(e) => setFieldValue('Dimension_width', e.target.value)}
                                                        isInvalid={!!errors.Dimension_width && touched.Dimension_width}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_width}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_height">
                                                    <Form.Label>Dimension Height<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_height"
                                                        placeholder="Enter Dimension height"
                                                        value={values.Dimension_height}
                                                     
                                                        onChange={(e) => setFieldValue('Dimension_height', e.target.value)}
                                                        isInvalid={!!errors.Dimension_height && touched.Dimension_height}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_height}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={6}>

                                                <Form.Group className='mt-3' controlId="Is_available_for_print_sale">
                                                    <Form.Label>Is Available for print sale?</Form.Label>
                                                    <Form.Select
                                                        name="Is_available_for_print_sale"
                                                        value={values.Is_available_for_print_sale || ""}
                                                   
                                                        onChange={(e) => setFieldValue('Is_available_for_print_sale', e.target.value)}
                                                        isInvalid={!!errors.Is_available_for_print_sale && touched.Is_available_for_print_sale}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Is_available_for_print_sale}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mt-3' controlId="Month">
                                                    <Form.Label>Month</Form.Label>
                                                    <Form.Select
                                                        name="Month"
                                                        value={values.Month || ""}
                                                 
                                                        onChange={(e) => setFieldValue('Month', e.target.value)}
                                                        isInvalid={!!errors.Month && touched.Month}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="jan">January</option>
                                                        <option value="feb">February</option>
                                                        <option value="mar">March</option>
                                                        <option value="apr">April</option>
                                                        <option value="may">May</option>
                                                        <option value="jun">June</option>
                                                        <option value="jul">July</option>
                                                        <option value="aug">August</option>
                                                        <option value="sep">September</option>
                                                        <option value="oct">October</option>
                                                        <option value="nov">November</option>
                                                        <option value="dec">December</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Month}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Limited_period_discount">
                                                    <Form.Label>Limited period discount</Form.Label>
                                                    <Form.Select
                                                        name="Limited_period_discount"
                                                        value={values.Limited_period_discount || ""}
                                                   
                                                        onChange={(e) => setFieldValue('Limited_period_discount', e.target.value)}
                                                        isInvalid={!!errors.Limited_period_discount && touched.Limited_period_discount}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Limited_period_discount}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Label_for_date">
                                                    <Form.Label>Label For Date</Form.Label>
                                                    <Flatpickr
                                                        className={`form-control flatpickr-input ${touched.Label_for_date && errors.Label_for_date ? 'is-invalid' : ''
                                                            }`}
                                                        placeholder='Enter publish date'
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                        }}
                                                        onChange={(date) => {
                                                            setFieldValue('Label_for_date', date[0]);
                                                        }}
                                                    />
                                                    {touched.Label_for_date && errors.Label_for_date && (
                                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                                            {errors.Label_for_date}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Price_on_request">
                                                    <Form.Label>Price On Request <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="Price_on_request"
                                                        value={values.Price_on_request || ""}
                                                        onChange={(e) => setFieldValue('Price_on_request', e.target.value)}
                                                        isInvalid={!!errors.Price_on_request && touched.Price_on_request}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Price_on_request}
                                                    </Form.Control.Feedback>
                                                </Form.Group>








                                                <Form.Group className='mt-3' controlId="Dimension_depth">
                                                    <Form.Label>Dimension Depth </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_depth"
                                                        placeholder="Enter dimension depth"
                                                        value={values.Dimension_depth}
                                                        onChange={(e) => setFieldValue('Dimension_depth', e.target.value)}
                                                        isInvalid={!!errors.Dimension_depth && touched.Dimension_depth}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_depth}
                                                    </Form.Control.Feedback>
                                                </Form.Group>




                                            </Col>


                                            <Form.Group className='mt-3' controlId="Description">
                                            <Form.Label>Description </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="Description"
                                                placeholder="Description"
                                                value={values.Description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Description && touched.Description}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
























                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Is_vailable_for_desktop_art">
                                                    <Form.Label>Is Available for desktop art?</Form.Label>
                                                    <Form.Select
                                                        name="Is_vailable_for_desktop_art"
                                                        value={values.Is_vailable_for_desktop_art || ""}
                                                        onChange={(e) => setFieldValue('Is_vailable_for_desktop_art', e.target.value)}
                                                        isInvalid={!!errors.Is_vailable_for_desktop_art && touched.Is_vailable_for_desktop_art}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Is_vailable_for_desktop_art}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Year">
                                                    <Form.Label>Year </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Year"
                                                        placeholder="Enter Year"
                                                        value={values.Year}
                                                   
                                                        onChange={(e) => setFieldValue('Year', e.target.value)}
                                                        isInvalid={!!errors.Year && touched.Year}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Year}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Creation_place">
                                                    <Form.Label>Creation place</Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Creation_place"
                                                        placeholder="Enter Creation place"
                                                        value={values.Creation_place}
                                                   
                                                        onChange={(e) => setFieldValue('Creation_place', e.target.value)}
                                                        isInvalid={!!errors.Creation_place && touched.Creation_place}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Creation_place}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Orientation">
  <Form.Label>Orientation</Form.Label>
  <Form.Select
    name="Orientation_id"
    value={values.Orientation_id || ""}

    onChange={(e) => setFieldValue('Orientation_id', e.target.value)}
    isInvalid={!!errors.Orientation_id && touched.Orientation_id}
  >
    <option value="">Select</option>
    {oriantationslist.map(item => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ))}
  </Form.Select>
  <Form.Control.Feedback type="invalid" className='text-danger'>
    {errors.Orientation_id}
  </Form.Control.Feedback>
</Form.Group>

                                                {/* <Form.Group className='mt-3' controlId="Price_on_request">
                                                    <Form.Label>Price On Request <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="Price_on_request"
                                                        value={values.Price_on_request || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Price_on_request && touched.Price_on_request}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Price_on_request}
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default EditProductStep1;